import React, {useState} from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

const YourCombinedComponent = ({fourthValues}) => {

  const [formData, setFormData] = useState({})
  fourthValues(formData);


  function handleChange(e){
    var checkbox = e.target;
    const {name} = e.target;
    console.log("checkbox", name)
    console.log("checkbox", checkbox)

    if(checkbox.checked){
      setFormData({ ...formData, [name]: "selected" })
    }
    else{
      const updatedData = {...formData};
      delete updatedData[`${name}`];
      console.log('updatedData', updatedData)
      setFormData(updatedData);

    }

  }


  const [backgroundColors, setBackgroundColors] = useState({});

  const handleClick = (e) => {
    // setBackgroundColor(backgroundColor === 'white' ? '#03cffc' : 'white');

    const {name} = e.target;
    console.log('name', name);
    setBackgroundColors(prevColors => ({
      ...prevColors,
      [name]: prevColors[name] === 'white' ? '#03cffc' : 'white',
    }));

  };


  return (
    
   


    <div style={{marginTop: '40px'}}>

<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.3.0/font/bootstrap-icons.css" />

<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.3.0/font/bootstrap-icons.css" />

      <form action="https://quikdox.com/will/maker/step-4/store" id="msform" method="post">
        
        <a id="draft-button-new" className="smooth-menu svedrft" style={{ display: "block", bottom: "187px", marginBottom: '30px'  }} title="Make new will">Choose Your Assets <i class="bi bi-info-circle-fill" title="An item or property owned by a person or company, regarded as having value and available to meet debts, commitments, or legacies. For example - house, car jewellery , bank accounts, etc."></i> </a>
        <input type="hidden" id="will_id" name="will_id" value="2327" />
        <fieldset>
          <div className="form-card">
            <h2 className="fs-title text-center"> <i className="fas fa-info-circle" title="An item or property owned by a person or company, regarded as having value and available to meet debts, commitments, or legacies. For example - house, car jewellery, bank accounts, etc."></i>
            </h2>
            <p style={{ color: "black", textAlign: "center" }}>Please click on the images to select your assets/ properties.</p>
            <hr style={{ opacity: "inherit" }} />
            <h4>Movable  <i class="bi bi-info-circle-fill" title="An item or property owned by a person or company, regarded as having value and available to meet debts, commitments, or legacies. For example - house, car jewellery , bank accounts, etc."></i>
            </h4>
            <ul className="row" style={{ margin: "0 auto", display:"flex"}}>
              <li className="col-md-2" style={{ textAlign: "center", margin: "10px 6px", padding: "10px 10px", display: "block" }}>
                <input className="sss" type="checkbox" id="myCheckbox0" name="jewellery" value="13"  onChange={handleChange}  />
                <label htmlFor="myCheckbox0" style={{ textAlign: "center", margin: "0px 6px", padding: "0px 10px" }}><img src="https://quikdox.com/assets/uploads/Jewellery.png" alt="Jewellery" /></label>
                <label htmlFor="" style={{ textAlign: "center", margin: "0px 6px", padding: "0px 10px" }}>Jewellery</label>
              </li>
              <li className="col-md-2" style={{ textAlign: "center", margin: "10px 6px", padding: "10px 10px", display: "block" }}>
                <input className="sss" type="checkbox" id="myCheckbox1" name="artifacts_antiques"   onChange={handleChange}/>
                <label htmlFor="myCheckbox1" style={{ textAlign: "center", margin: "0px 6px", padding: "0px 10px" }}><img src="https://quikdox.com/assets/uploads/Artifacts.png" alt="Artifacts/ Antiques" /></label>
                <label htmlFor="" style={{ textAlign: "center", margin: "0px 6px", padding: "0px 10px" }}>Artifacts/ Antiques</label>
              </li>
              <li className="col-md-2" style={{ textAlign: "center", margin: "10px 6px", padding: "10px 10px", display: "block" }}>
                <input className="sss" type="checkbox" id="myCheckbox1" name="vehicle"  onChange={handleChange}  />
                <label htmlFor="myCheckbox1" style={{ textAlign: "center", margin: "0px 6px", padding: "0px 10px" }}><img src="https://quikdox.com/assets/uploads/Vehicle.png" alt="Artifacts/ Antiques" /></label>
                <label htmlFor="" style={{ textAlign: "center", margin: "0px 6px", padding: "0px 10px" }}>Vehicle</label>
              </li>


              <li className="col-md-2" style={{ textAlign: "center", margin: "10px 6px", padding: "10px 10px", display: "block" }}>
                <input className="sss" type="checkbox" id="myCheckbox1" name="bank_account"  onChange={handleChange} />
                <label htmlFor="myCheckbox1" style={{ textAlign: "center", margin: "0px 6px", padding: "0px 10px" }}><img src="https://quikdox.com/assets/uploads/Bank-Account.png" alt="Artifacts/ Antiques" /></label>
                <label htmlFor="" style={{ textAlign: "center", margin: "0px 6px", padding: "0px 10px" }}>Bank Account</label>
              </li>
              <li className="col-md-2" style={{ textAlign: "center", margin: "10px 6px", padding: "10px 10px", display: "block" }}>
                <input className="sss" type="checkbox" id="myCheckbox1" name="fixed_deposit" value="14"  onChange={handleChange}  />
                <label htmlFor="myCheckbox1" style={{ textAlign: "center", margin: "0px 6px", padding: "0px 10px" }}><img src="https://quikdox.com/assets/uploads/Foxed-Deposits.png" alt="Artifacts/ Antiques" /></label>
                <label htmlFor="" style={{ textAlign: "center", margin: "0px 6px", padding: "0px 10px" }}>Fixed Deposit</label>
              </li>
              
     
            </ul>
            <br />
            <ul className="row" style={{ margin: "0 auto", display:"flex"}}>
              <li className="col-md-2" style={{ textAlign: "center", margin: "10px 6px", padding: "10px 10px", display: "block" }}>
                <input className="sss" type="checkbox" id="myCheckbox0" name="lockers" value="13"   onChange={handleChange}/>
                <label htmlFor="myCheckbox0" style={{ textAlign: "center", margin: "0px 6px", padding: "0px 10px" }}><img src="https://quikdox.com/assets/uploads/Lockers.png" alt="Jewellery" /></label>
                <label htmlFor="" style={{ textAlign: "center", margin: "0px 6px", padding: "0px 10px" }}>Lockers
</label>
              </li>
              <li className="col-md-2" style={{ textAlign: "center", margin: "10px 6px", padding: "10px 10px", display: "block" }}>
                <input className="sss" type="checkbox" id="myCheckbox1" name="mutual_funds" value="14"  onChange={handleChange} />
                <label htmlFor="myCheckbox1" style={{ textAlign: "center", margin: "0px 6px", padding: "0px 10px" }}><img src="https://quikdox.com/assets/uploads/Mutual-Funds.png" alt="Artifacts/ Antiques" /></label>
                <label htmlFor="" style={{ textAlign: "center", margin: "0px 6px", padding: "0px 10px" }}>Mutual funds</label>
              </li>
              <li className="col-md-2" style={{ textAlign: "center", margin: "10px 6px", padding: "10px 10px", display: "block" }}>
                <input className="sss" type="checkbox" id="myCheckbox1" name="PPF" value="14"   onChange={handleChange}/>
                <label htmlFor="myCheckbox1" style={{ textAlign: "center", margin: "0px 6px", padding: "0px 10px" }}><img src="https://quikdox.com/public/assets/uploads/PPF.png" alt="Artifacts/ Antiques" /></label>
                <label htmlFor="" style={{ textAlign: "center", margin: "0px 6px", padding: "0px 10px" }}>PPF</label>
              </li>


          
              <li className="col-md-2" style={{ textAlign: "center", margin: "10px 6px", padding: "10px 10px", display: "block" }}>
                <input className="sss" type="checkbox" id="myCheckbox1" name="PF" value="14"   onChange={handleChange}/>
                <label htmlFor="myCheckbox1" style={{ textAlign: "center", margin: "0px 6px", padding: "0px 10px" }}><img src="https://quikdox.com/public/assets/uploads/PPF.png" alt="Artifacts/ Antiques" /></label>
                <label htmlFor="" style={{ textAlign: "center", margin: "0px 6px", padding: "0px 10px" }}>PF</label>
              </li>
              
              <li className="col-md-2" style={{ textAlign: "center", margin: "10px 6px", padding: "10px 10px", display: "block" }}>
                <input className="sss" type="checkbox" id="myCheckbox1" name="gratuity" value="14"  onChange={handleChange} />
                <label htmlFor="myCheckbox1" style={{ textAlign: "center", margin: "0px 6px", padding: "0px 10px" }}><img src="https://quikdox.com/assets/uploads/Gratuity.png" alt="Artifacts/ Antiques" /></label>
                <label htmlFor="" style={{ textAlign: "center", margin: "0px 6px", padding: "0px 10px" }}>Gratuity</label>
              </li>
              
     
            </ul>
            <br/>
            <ul className="row" style={{ margin: "0 auto", display:"flex"}}>
              <li className="col-md-2" style={{ textAlign: "center", margin: "10px 6px", padding: "10px 10px", display: "block" }}>
                <input className="sss" type="checkbox" id="myCheckbox0" name="Life_insurance_policy" value="13"  onChange={handleChange} />
                <label htmlFor="myCheckbox0" style={{ textAlign: "center", margin: "0px 6px", padding: "0px 10px" }}><img src="https://quikdox.com/assets/uploads/Life-Insurance.png" alt="Jewellery" /></label>
                <label htmlFor="" style={{ textAlign: "center", margin: "0px 6px", padding: "0px 10px" }}>Life insurance Policy</label>
              </li>
              <li className="col-md-2" style={{ textAlign: "center", margin: "10px 6px", padding: "10px 10px", display: "block" }}>
                <input className="sss" type="checkbox" id="myCheckbox1" name="Equity_shares_debentures_commodity" value="14"  onChange={handleChange} />
                <label htmlFor="myCheckbox1" style={{ textAlign: "center", margin: "0px 6px", padding: "0px 10px" }}><img src="https://quikdox.com/assets/uploads/Equity-Shares.png" alt="Artifacts/ Antiques" /></label>
                <label htmlFor="" style={{ textAlign: "center", margin: "0px 6px", padding: "0px 10px" }}>Equity/shares/ debentures/commodity</label>
              </li>
              <li className="col-md-2" style={{ textAlign: "center", margin: "10px 6px", padding: "10px 10px", display: "block" }}>
                <input className="sss" type="checkbox" id="myCheckbox1" name="crypto_currency" value="14"   onChange={handleChange}/>
                <label htmlFor="myCheckbox1" style={{ textAlign: "center", margin: "0px 6px", padding: "0px 10px" }}><img src="https://quikdox.com/assets/uploads/Crypto-Currency.png" alt="Artifacts/ Antiques" /></label>
                <label htmlFor="" style={{ textAlign: "center", margin: "0px 6px", padding: "0px 10px" }}>Crypto Currency</label>
              </li>


          
              <li className="col-md-2" style={{ textAlign: "center", margin: "10px 6px", padding: "10px 10px", display: "block" }}>
                <input className="sss" type="checkbox" id="myCheckbox1" name="NSC" value="14"  onChange={handleChange} />
                <label htmlFor="myCheckbox1" style={{ textAlign: "center", margin: "0px 6px", padding: "0px 10px" }}><img src="https://quikdox.com/assets/uploads/NSC.png" alt="Artifacts/ Antiques" /></label>
                <label htmlFor="" style={{ textAlign: "center", margin: "0px 6px", padding: "0px 10px" }}>NSC</label>
              </li>
              
              <li className="col-md-2" style={{ textAlign: "center", margin: "10px 6px", padding: "10px 10px", display: "block" }}>
                <input className="sss" type="checkbox" id="myCheckbox1" name="others" value="14"   onChange={handleChange}/>
                <label htmlFor="myCheckbox1" style={{ textAlign: "center", margin: "0px 6px", padding: "0px 10px" }}><img src="https://quikdox.com/assets/uploads/Others.png" alt="Artifacts/ Antiques" /></label>
                <label htmlFor=""style={{ textAlign: "center", margin: "0px 6px", padding: "0px 10px" }}>Others</label>
              </li>
              
     
            </ul>
          <br/>
         
          <h4>Immovable <i class="bi bi-info-circle-fill" title="Immovable assets are those assets which can be touched however cannot be moved. For example- commercial or residential property, warehouse factory etc."></i>
            </h4>
            <ul className="row" style={{ margin: "0 auto", display:"flex", listStyle: 'none' }}>

                                                                                          
<li className="col-md-2" style={{ textAlign: "center", margin: "10px 6px", padding: "10px 10px", display: "block" }}>
                <input className="sss" type="checkbox" id="myCheckbox1" name="commercial_property" value="14"   onChange={handleChange}/>
                <label htmlFor="myCheckbox1" style={{ textAlign: "center", margin: "0px 6px", padding: "0px 10px" }}><img src="https://quikdox.com/assets/uploads/Commercial-property.png"alt="Artifacts/ Antiques" /></label>
                <label htmlFor=""style={{ textAlign: "center", margin: "0px 6px", padding: "0px 10px" }}>Commercial Property</label>
              </li>

  

<li className="col-md-2" style={{ textAlign: "center", margin: "10px 6px", padding: "10px 10px", display: "block" }}>
                <input className="sss" type="checkbox" id="myCheckbox1" name="residential_property" value="14"   onChange={handleChange}/>
                <label htmlFor="myCheckbox1" style={{ textAlign: "center", margin: "0px 6px", padding: "0px 10px" }}><img   src="https://quikdox.com/assets/uploads/Residential-property.png"   alt="Artifacts/ Antiques" /></label>
                <label htmlFor=""style={{ textAlign: "center", margin: "0px 6px", padding: "0px 10px" }}>Residential Property</label>
              </li>



{/* 
<div  >
      <img 
      name='residential_property'
        src="https://quikdox.com/assets/uploads/Residential-property.png" 
        style={{ 
          display: "block", 
          margin: "0 auto", 
          cursor: "pointer" ,
          backgroundColor: backgroundColors['residential_property']
        }} 
        onClick={handleClick} // Attach click event handler to change background color
      />Residential Property
    </div>


             */}

                                                                                                                                        


            </ul>



            
            
          </div>
        </fieldset>
        
      </form>
    </div>
  );
};

export default YourCombinedComponent;
