import React, { useState, useEffect } from "react";

function AssetAllocationForm({ newValues, eightValues }) {
  const [selectedOption, setSelectedOption] = useState("sameRatio");
  const [rowCount, setRowCount] = useState(0);
  const [formData, setFormData] = useState({});

  eightValues(formData);

  const [beneficiaries, setBeneficiaries] = useState(
    Array.from({ length: 5 }, (_, index) => ({
      name: "",
      relationship: "",
      share: "",
      key: index,
    }))
  );

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // const handleSelectedSame = (e) => {
  //   setSelectedOption("sameRatio")
  //   const { name, checked } = e.target;

  //   if(checked){
  //   const updateRowCount = () => {   
  //       const currentRowCount = Object.keys(newValues).filter((key) =>
  //         key.startsWith("Beneficiary_")
  //       ).length;
  //       setRowCount(currentRowCount);

  //       const value = 100 / currentRowCount;

  //       const updatedFormData = { ...formData };

  //       Object.keys(newValues).forEach((sharer) => {
  //         if (sharer.startsWith("Beneficiary_")) {
  //           const beneficiaryId = sharer.split("_")[1];
  //           const beneficiaryKey = sharer.split("_").slice(2).join("_"); // Get everything after the second underscore
  //           const shareKey = beneficiaryKey
  //             ? `share_${beneficiaryId}_${beneficiaryKey}`
  //             : `share_${beneficiaryId}`;
  //           updatedFormData[shareKey] = value;
  //         }
  //       });

  //       setFormData(updatedFormData);
      
      
  //   };

  //   updateRowCount();
  // }

  // };


  const handleSelectedSeparate = (e) => {
    setSelectedOption("separate")
  };
  const handleSelectedSame = (e) => {
    setSelectedOption("sameRatio")
  };


  useEffect(() => {
    const updateRowCount = () => {
      if (selectedOption === "sameRatio") {
        const currentRowCount = Object.keys(newValues).filter((key) =>
          key.startsWith("Beneficiary_")
        ).length;
        setRowCount(currentRowCount);

        const value = 100 / currentRowCount;

        const updatedFormData = { ...formData };

        Object.keys(newValues).forEach((sharer) => {
          if (sharer.startsWith("Beneficiary_")) {
            const beneficiaryId = sharer.split("_")[1];
            const beneficiaryKey = sharer.split("_").slice(2).join("_"); // Get everything after the second underscore
            const shareKey = beneficiaryKey
              ? `share_${beneficiaryId}_${beneficiaryKey}`
              : `share_${beneficiaryId}`;
            updatedFormData[shareKey] = value;
          }
        });

        setFormData(updatedFormData);
      }
      
    };

    updateRowCount();
  }, []);

  return (
    <div style={{ maxWidth: "800px", margin: "auto", padding: "20px" }}>
      <div
        style={{ marginBottom: "20px", display: "flex", alignItems: "center" }}
      >
        <div style={{ marginRight: "20px" }}>
          <input
            type="radio"
            id="sameRatio"
            name="assetAllocation"
            value="sameRatio"
            checked={selectedOption === "sameRatio"}
            onChange={handleSelectedSame}
          />
          <label htmlFor="sameRatio" style={{ marginLeft: "5px" }}>
            I want to give ALL my assets / properties in SAME RATIO to
            beneficiaries
          </label>
        </div>
        <div>
          <input
            type="radio"
            id="separate"
            name="assetAllocation"
            value="separate"
            checked={selectedOption === "separate"}
            onChange={handleSelectedSeparate}
          />
          <label htmlFor="separate" style={{ marginLeft: "5px" }}>
            I want to give / allocate each asset SEPARATELY
          </label>
        </div>
      </div>

      {/* {selectedOption === 'sameRatio' && ( */}
      <table
        id="myTable"
        style={{
          width: "100%",
          borderCollapse: "collapse",
          marginBottom: "20px",
        }}
      >
        <thead>
          <tr>
            <th style={tableHeaderStyle}>BENEFICIARY</th>
            <th style={tableHeaderStyle}>RELATIONSHIP</th>
            <th style={tableHeaderStyle}>SHARE IN PERCENTAGE %</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            {newValues["Beneficiary_father"] && (
              <React.Fragment>
                <td>
                  <input
                    type="text"
                    value={newValues.father_name}
                    style={inputStyle}
                  />
                </td>
                <td style={tableCellStyle}>
                  <input type="text" value="Father" style={inputStyle} />
                </td>
                <td style={tableCellStyle}>
                  <input
                    type="text"
                    name="share_father"
                    value={formData["share_father"]}
                    onChange={handleInputChange}
                    style={inputStyle}
                  />
                </td>
              </React.Fragment>
            )}
          </tr>

          <tr>
            {newValues["Beneficiary_mother"] && (
              <React.Fragment>
                <td>
                  <input
                    type="text"
                    value={newValues.mother_name}
                    style={inputStyle}
                  />
                </td>
                <td style={tableCellStyle}>
                  <input type="text" value="Mother" style={inputStyle} />
                </td>
                <td style={tableCellStyle}>
                  <input
                    type="text"
                    name="share_mother"
                    value={formData["share_mother"]}
                    onChange={handleInputChange}
                    style={inputStyle}
                  />
                </td>
              </React.Fragment>
            )}
          </tr>

          <tr>
            {newValues["Beneficiary_spouse"] && (
              <React.Fragment>
                <td>
                  <input
                    type="text"
                    value={newValues.spouse_name}
                    style={inputStyle}
                  />
                </td>
                <td style={tableCellStyle}>
                  <input type="text" value="Spouse" style={inputStyle} />
                </td>
                <td style={tableCellStyle}>
                  <input
                    type="text"
                    name="share_spouse"
                    value={formData["share_spouse"]}
                    onChange={handleInputChange}
                    style={inputStyle}
                  />
                </td>
              </React.Fragment>
            )}
          </tr>

          {[1, 2, 3, 4, 5].map(
            (siblingNumber) =>
              newValues[`Beneficiary_sibling_${siblingNumber}`] && (
                <React.Fragment>
                  <tr>
                    <td>
                      <input
                        type="text"
                        value={newValues[`sibling_${siblingNumber}_name`]}
                        style={inputStyle}
                      />
                    </td>
                    <td style={tableCellStyle}>
                      <input
                        type="text"
                        value={newValues[`sibling_${siblingNumber}`]}
                        style={inputStyle}
                      />
                    </td>
                    <td style={tableCellStyle}>
                      <input
                        type="text"
                        name={`share_sibling_${siblingNumber}`}
                        value={formData[`share_sibling_${siblingNumber}`]}
                        onChange={handleInputChange}
                        style={inputStyle}
                      />
                    </td>
                  </tr>
                </React.Fragment>
              )
          )}

          {[1, 2, 3, 4, 5].map(
            (childrenNumber) =>
              newValues[`Beneficiary_children_${childrenNumber}`] && (
                <React.Fragment>
                  <tr>
                    <td>
                      <input
                        type="text"
                        value={newValues[`children_${childrenNumber}_name`]}
                        style={inputStyle}
                      />
                    </td>
                    <td style={tableCellStyle}>
                      <input
                        type="text"
                        value={newValues[`children_${childrenNumber}`]}
                        style={inputStyle}
                      />
                    </td>
                    <td style={tableCellStyle}>
                      <input
                        type="text"
                        name={`share_children_${childrenNumber}`}
                        value={formData[`share_children_${childrenNumber}`]}
                        onChange={handleInputChange}
                        style={inputStyle}
                      />
                    </td>
                  </tr>
                </React.Fragment>
              )
          )}
        </tbody>
      </table>
    </div>
  );
}

const tableHeaderStyle = {
  backgroundColor: "#f2f2f2",
  padding: "15px",
  textAlign: "left",
};

const tableCellStyle = {
  borderBottom: "1px solid #ddd",
  padding: "15px",
};

const inputStyle = {
  width: "100%",
  padding: "10px",
};

export default AssetAllocationForm;
