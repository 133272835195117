
import React, { useState } from 'react';
import {
  TextField,
  Checkbox,
  FormControlLabel,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
} from '@mui/material';
import { green } from '@mui/material/colors';

export default function MultiStepForm({sixthValues}) {
  const [showFormData, setShowFormData] = useState(false);
  const [showAdditionalData, setShowAdditionalData] = useState(false);
  const [occupation, setOccupation] = React.useState('');
  const [occupations, setOccupations] = React.useState('');
  const [gender, setGender] = React.useState('');
  const [genders, setGenders] = React.useState('');
  const [submission, setSubmission] = React.useState('');
 
  const [assignExecutor, setAssignExecutor] = useState(false);
  const [formData, setFormData] = useState({})

 
  sixthValues(formData);

  const handleCheckboxChange = (event) => {
    

    setShowFormData(event.target.checked);
        const { name, checked } = event.target;
        
        if(checked){
          setAssignExecutor(checked);
          console.log('checked name', name)
          console.log('checked checked', checked)
            setFormData({ ...formData, [name]: "yes" })
        }
       
    
  };
  const handleCheckboxChanges = (e) => {
    setShowFormData(e.target.checked);

    const { name, checked } = e.target;
 if(checked){

   setAssignExecutor(!assignExecutor)
   const updatedFormData = { ...formData };
   delete updatedFormData[name];
   setFormData(updatedFormData);
   
  }
  };


  const  handleChangeOther = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value })
  };


  const handleChange = (event) => {
    setGender(event.target.value);
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value })

  };
  
  const handleChanges = (event) => {
    setGenders(event.target.value);
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value })

  };

  const handleChangeSubmission = (event) => {
    setSubmission(event.target.value);
  const { name, value } = event.target;
  setFormData({ ...formData, [name]: value })
  };

  const handleAdditionalCheckboxChange = (e) => {

         setShowAdditionalData(e.target.checked);
      };
      

  

  const handleChangeOccupation = (e) => {
    setOccupation(e.target.value);
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value })
  }
  
  const handleChangeOccupations = (e) => {
    setOccupations(e.target.value);
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value })
  }

  


  return (
    <div>
      <h1 style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>Executor</h1>
     <h3>Appoint an Executor</h3>

      <div>
        <FormControlLabel
          control={
            <Checkbox
              checked={showFormData}
              onChange={handleCheckboxChanges}
              sx={{ '& .MuiSvgIcon-root': { fontSize: 36, color: green[500] } }}
            />
          }
          label="Yes"
          sx={{ fontSize: 20 }}
        />

        <FormControlLabel
          control={
            <Checkbox
              checked={!showFormData}
              onChange={() => setShowFormData(!showFormData)}
              sx={{ '& .MuiSvgIcon-root': { fontSize: 36 } }}
            />
          }
          label="No"
          sx={{ fontSize: 20 }}
        />
      </div>

      {showFormData && (
        <div style={{ margin: '10px' }}>
          <h1>Please fill details of executor</h1>
          <TextField label="Full Name Of The Executor" name="executor_name" margin="normal" onChange={handleChangeOther} variant="outlined" color="secondary" required style={{ width: "520px", marginRight: "10px" }} />
          <TextField label="Child Of" name="executor_child_of"  onChange={handleChangeOther}  margin="normal" variant="outlined" color="secondary" required style={{ width: "520px", marginRight: "10px" }} />

          <FormControl style={{ marginTop: "16px" }}>
                    <InputLabel id="occupation">Occupation</InputLabel>
                    <Select
                        labelId="occupation"
                        name="executor_occupation"
                        id="demo-simple-select"
                        value={occupation}
                        label="Occupation"
                        onChange={handleChangeOccupation}
                        color="secondary" style={{ width: "520px", marginRight: "10px" }}
                    >
                        <MenuItem value={"Self Employed"}>Self Employed</MenuItem>
                        <MenuItem value={"Service"}>Service</MenuItem>
                        <MenuItem value={"Business"}>Business</MenuItem>
                        <MenuItem value={"Home Maker"}>Home Maker</MenuItem>
                        <MenuItem value={"Student"}>Student</MenuItem>
                        <MenuItem value={"Retired"}>Retired</MenuItem>
                        <MenuItem value={"Other"}>Other</MenuItem>
                    </Select>
                </FormControl>

                <TextField label="Age"  name="executor_age"  margin="normal"  onChange={handleChangeOther} variant="outlined" color="secondary" style={{ width: "520px" }}/>
       
  <FormControl style={{ marginTop: "16px" }}>

<InputLabel id="gender">Gender</InputLabel>
<Select
    labelId="gender"
    name="executor_gender"
    id="demo-simple-select"
    value={gender}
    label="gender"
    onChange={handleChange}
    color="secondary" style={{ width: "520px", marginRight: "10px" }}
>
    <MenuItem value={"male"}>Male</MenuItem>
    <MenuItem value={"female"}>Female</MenuItem>
    <MenuItem value={"other"}>Other</MenuItem>
</Select>
</FormControl>

<TextField label="PinCode"  onChange={handleChangeOther} name="executor_pincode" margin="normal" variant="outlined" color="secondary" style={{ width: "520px" }} />

<TextField label="Address Of Excecutor"  onChange={handleChangeOther} name="executor_address" margin="normal" variant="outlined" color="secondary" style={{ width: "520px" }} />


          <div>
          <h3>Please fill details of Alternate Executor</h3>
            <FormControlLabel
              control={
                <Checkbox
                  checked={showAdditionalData}
                  onChange={handleAdditionalCheckboxChange}
                  sx={{ '& .MuiSvgIcon-root': { fontSize: 36, color: green[500] } }}
                />
              }
              label="Yes"
              sx={{ fontSize: 20 }}
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={!showAdditionalData}
                  onChange={() => setShowAdditionalData(!showAdditionalData)}
                  sx={{ '& .MuiSvgIcon-root': { fontSize: 36 } }}
                />
              }
              label="No"
              sx={{ fontSize: 20 }}
            />
          </div>
    
          {showAdditionalData && (
            <div style={{ margin: '10px' }}>
           
              <TextField
            label="Full Name Of The Alternate Executor"     
            margin="normal"
            variant="outlined"
            color="secondary"
            name="alternate_executor_name"
            onChange={handleChangeOther}
            style={{ width: '500px', marginRight: '10px' }}
          />
          <TextField
            label="Child Of"
            margin="normal"
            variant="outlined"
            color="secondary"
            name="alternate_executor_child_of"
            onChange={handleChangeOther}
            required
            style={{ width: '520px', marginRight: '10px' }}
          />

          <FormControl style={{ marginTop: '16px' }}>
            <InputLabel id="occupation">Occupation</InputLabel>
            <Select
              labelId="occupation"
              id="demo-simple-select"
              value={occupations}
              label="Occupation"
              name="alternate_executor_occupation_of"
              onChange={handleChangeOccupations}
              color="secondary"
              style={{ width: '500px', marginRight: '10px' }}
            >
              <MenuItem value={'Self Employed'}>Self Employed</MenuItem>
              <MenuItem value={'Service'}>Service</MenuItem>
              <MenuItem value={'Business'}>Business</MenuItem>
              <MenuItem value={'Home Maker'}>Home Maker</MenuItem>
              <MenuItem value={'Student'}>Student</MenuItem>
              <MenuItem value={'Retired'}>Retired</MenuItem>
              <MenuItem value={'Other'}>Other</MenuItem>
            </Select>
          </FormControl>

          <TextField
            label="Age"
            margin="normal"
            name="alternate_executor_age_of"
            onChange={handleChangeOther}
            variant="outlined"
            color="secondary"
            style={{ width: '500px' }}
          />

          <FormControl style={{ marginTop: '16px' }}>
            <InputLabel id="gender">Gender</InputLabel>
            <Select
              labelId="gender"
              id="demo-simple-select"
              value={genders}
              label="gender"
              name="alternate_executor_gender_of"
              onChange={handleChanges}
              color="secondary"
              style={{ width: '500px', marginRight: '10px' }}
            >
              <MenuItem value={'male'}>Male</MenuItem>
              <MenuItem value={'female'}>Female</MenuItem>
              <MenuItem value={'other'}>Other</MenuItem>
            </Select>
          </FormControl>

          <TextField
            label="PinCode"
            margin="normal"
            variant="outlined"
            name="alternate_executor_pincode_of"
            onChange={handleChangeOther}
            color="secondary"
            style={{ width: '500px' }}
          />

          <TextField
            label="Address Of Executor"
            margin="normal"
            name="alternate_executor_address_of"
            onChange={handleChangeOther}
            variant="outlined"
            color="secondary"
            style={{ width: '500px' }}
          />
       
                          </div>
                         
          )}
         
        </div>
       
     
      )}
      <h3>Do you want us to assign us as the executor</h3>
      {/* <h2>Do you want us to assign us as the executor</h2> */}
      {/* <FormControlLabel
        control={
          <Checkbox
         
            // onChange={handleCheckboxChange}
            name="assignExecutor"
            color="primary"
          />
        }
        label="Yes"
      /> */}
      <FormControlLabel
          control={
            <Checkbox
            checked={assignExecutor}
            name="assign_us_as_executor"
            name2="assign"
              onChange={handleCheckboxChange}
              sx={{ '& .MuiSvgIcon-root': { fontSize: 36, color: green[500] } }}
            />
          }
          label="Yes"
          sx={{ fontSize: 20 }}
        />
      {/* <FormControlLabel
        control={
          <Checkbox
            checked={!assignExecutor}
            onChange={() => setAssignExecutor(!assignExecutor)}
            name="assignExecutor"
            color="primary"
          />
        }
        label="No"
      /> */}

      <FormControlLabel
          control={
            <Checkbox
            name="assign_us_as_executor"
            name2="unAssign"
            checked={!assignExecutor}
              onChange={handleCheckboxChanges}
              sx={{ '& .MuiSvgIcon-root': { fontSize: 36 } }}
            />
          }
          label="No"
          sx={{ fontSize: 20 }}
        />
     
   

    </div>
  );
}
