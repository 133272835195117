import React, { useState } from "react";
import { TextField, Select, MenuItem, InputLabel, FormControl, Button,Checkbox,FormControlLabel } from "@mui/material";


const BeneficiaryFields = ({ beneficiary, handleChangeBeneficiary, handleDeleteBeneficiary, index }) => (
    <div style={{ marginTop: "16px" }}>
        <TextField
            label="FullName"
            margin="normal"
            variant="outlined"
            color="secondary"
            style={{ width: "520px", marginRight: "10px" }}
            value={beneficiary.name}
        />
        <TextField
            label="Age"
            margin="normal"
            variant="outlined"
            color="secondary"
            style={{ width: "520px" }}
            value={beneficiary.age}
        />
        <FormControl style={{ marginTop: "8px" }}>
            <InputLabel>Select Will Type</InputLabel>
            <Select
                label="Make my Will"
                value={beneficiary.will}
                color="secondary"
                style={{ width: "520px", marginRight: "10px" }}
            >
                <MenuItem value={"Premium QuickWill for Self"}>Premium QuickWill for Self</MenuItem>
                <MenuItem value={"Premium QuickWill for Couples"}>Premium QuickWill for Couples</MenuItem>
                <MenuItem value={"Premium QuickWill Representative"}>Premium QuickWill Representative</MenuItem>
                <MenuItem value={"Premium QuickWill Joint"}>Premium QuickWill Joint</MenuItem>
                <MenuItem value={"Standard QuickWill for Self"}>Standard QuickWill for Self</MenuItem>
            </Select>
        </FormControl>
        <TextField
            label="Address"
            variant="outlined"
            color="secondary"
            style={{ width: "520px", marginTop: "8px" }}
            value={beneficiary.address}
        />
        <TextField
            label="PinCode"
            variant="outlined"
            color="secondary"
            style={{ width: "520px", marginTop: "8px" }}
            value={beneficiary.pincode}
        />
        <Button
            variant="contained"
            color="primary"
            onClick={() => handleDeleteBeneficiary(index)}
            style={{ marginLeft: "10px", marginTop: "8px" }}
        >
            Delete Beneficiary
        </Button>
    </div>
);

export default function StepThree({newValues, thirdValues }) {
    const [beneficiaryInput, setBeneficiaryInput] = useState({ name: "", age: "", will: "", address: "", pincode: "" });
    const [beneficiaryInputs, setBeneficiaryInputs] = useState({ name: "", age: "", will: "", address: "", pincode: "" });
    const [beneficiaryChildren, setBeneficiaryInputsChildren] = useState({ name: "", age: "", will: "", address: "", pincode: "" });
    const [beneficiarySibling, setBeneficiaryInputsSibling] = useState({ name: "", age: "", will: "", address: "", pincode: "" });
    const [beneficiaries, setBeneficiaries] = useState([]);
    const [formData, setFormData] = useState({})

 
    thirdValues(formData);

    
    const handleAddBeneficiary = () => {
        setBeneficiaries([...beneficiaries, beneficiaryInput]);
        // Reset the form for next input
        setBeneficiaryInput({ name: "", age: "", will: "", address: "", pincode: "" });
    };
 
    

    const handleDeleteBeneficiary = (index) => {
        const updatedBeneficiaries = [...beneficiaries];
        updatedBeneficiaries.splice(index, 1);
        setBeneficiaries(updatedBeneficiaries);
    };

    const handleChangeBeneficiaryInput = (event, key) => {
        const { checked, name } = event.target;
        setBeneficiaryInput({ ...beneficiaryInput, [key]: checked });

    if(checked){
        setFormData({ ...formData, [name]: "yes" })
    }
    else{
        const updatedFormData = { ...formData };
        delete updatedFormData[name];
        setFormData(updatedFormData);
    }
    };

    
    const handleChangeBeneficiaryInputMother = (event, key) => {
        const {name, checked } = event.target;
        setBeneficiaryInput({ ...beneficiaryInput, [key]: checked });

        if(checked){
            setFormData({ ...formData, [name]: "yes" })
        }
        else{
            const updatedFormData = { ...formData };
            delete updatedFormData[name];
            setFormData(updatedFormData);
        }

    };


    const handleChangeBeneficiaryInputSpouse = (event, key) => {
        const {name, checked } = event.target;
        setBeneficiaryInputs({ ...beneficiaryInputs, [key]: checked });

        if(checked){
            setFormData({ ...formData, [name]: "yes" })
        }
        else{
            const updatedFormData = { ...formData };
            delete updatedFormData[name];
            setFormData(updatedFormData);
        }
    };

  const handleChangeBeneficiaryInputChildren=(event,key)=>{
    const {name,checked}=event.target;
    setBeneficiaryInputsChildren({ ...beneficiaryChildren, [key]: checked });

    if(checked){
        setFormData({ ...formData, [name]: "yes" })
    }
    else{
        const updatedFormData = { ...formData };
        delete updatedFormData[name];
        setFormData(updatedFormData);
    }

  }



  const handleChangeBeneficiaryInputSibling=(event,key)=>{
      const {name, checked}=event.target;
      setBeneficiaryInputsSibling({...beneficiarySibling, [key]: checked});

      if(checked){
        setFormData({ ...formData, [name]: "yes" })
    }
    else{
        const updatedFormData = { ...formData };
        delete updatedFormData[name];
        setFormData(updatedFormData);
    }
  }
    

    return (
        <>
          {/* <Button variant="contained" color="primary" onClick={handleAddBeneficiary} style={{ marginTop: "18px" }}>
                    Add Beneficiary
                </Button> */}
            <div style={{ margin: "10px" }}>



            {
 newValues[`father_name`] && (
    <React.Fragment>



            <h2 style={{ fontWeight: "600" }}>Father Details <span><Button variant="content" style={{ float: "right" }} ></Button></span></h2>
            <FormControlLabel
    control={
        <Checkbox
            checked={beneficiaryInput.isSelected} // Assuming you have a state to control this
            onChange={(e) => handleChangeBeneficiaryInput(e, "isSelected")} // Adjust your handling function
            name="Beneficiary_father"
            color="secondary"
        />
    }
    label=""
    style={{ margin: "normal", marginRight: "10px" }}
/>
<TextField
    placeholder="Father" // Empty since we're using a label as a placeholder
    // label={beneficiaryInput.Relation ? "" : "Father"} // Hide label if there's a value
    margin="normal"
    variant="outlined"
    color="secondary"
    style={{ width: "500px", marginRight: "10px", pointerEvents: "none", color: '#000' }} // Prevent interaction
    value={beneficiaryInput.Relation}
    InputProps={{
        readOnly: true, // Make field read-only instead of disabled to keep custom styles
        style: {
            color: '#000', // Text color
        },
        inputProps: {
            style: {
                fontWeight: 'bold',
                color: '#555', // This now applies to the text instead of the placeholder
            }
        }
    }}
    InputLabelProps={{
        shrink: true,
        style: {
            color: '#555', // Darker label to act as the placeholder
            fontWeight: 'bold',
        },
    }}
/>

                 <TextField
                    placeholder="Fathers Name"
                    value={newValues.father_name}
                    margin="normal"
                    variant="outlined"
                    color="secondary"
                    style={{ width: "500px",marginRight: "10px" }}
                    onChange={(e) => handleChangeBeneficiaryInput(e, "age")}
                />

</React.Fragment>
    )
    
}




{
 newValues[`mother_name`] && (
    <React.Fragment>

 

<h2 style={{ fontWeight: "600" }}>Mother Details <span><Button variant="content" style={{ float: "right" }} ></Button></span></h2>
                       <FormControlLabel
    control={
        <Checkbox
            checked={beneficiaryInput.isSelected} // Assuming you have a state to control this
            onChange={(e) => handleChangeBeneficiaryInputMother(e, "isSelected")} // Adjust your handling function
            name="Beneficiary_mother"
            color="secondary"
        />
    }
    label=""
    style={{ margin: "normal", marginRight: "10px" }}
/>

<TextField
    placeholder="Mother" // Empty since we're using a label as a placeholder
   
    margin="normal"
    variant="outlined"
    color="secondary"
    style={{ width: "500px", marginRight: "10px", pointerEvents: "none", color: '#000' }} // Prevent interaction
    value={beneficiaryInput.Relation}
    InputProps={{
        readOnly: true, // Make field read-only instead of disabled to keep custom styles
        style: {
            color: '#000', // Text color
        },
        inputProps: {
            style: {
                fontWeight: 'bold',
                color: '#555', // This now applies to the text instead of the placeholder
            }
        }
    }}
    InputLabelProps={{
        shrink: true,
        style: {
            color: '#555', // Darker label to act as the placeholder
            fontWeight: 'bold',
        },
    }}
/>
                 <TextField
                    placeholder="Mothers Name"
                    margin="normal"
                    variant="outlined"
                    color="secondary"
                    style={{ width: "500px",marginRight: "10px" }}
                    value={newValues.mother_name}
                    // onChange={(e) => handleChangeBeneficiaryInput(e, "age")}
                />

</React.Fragment>
    )
    
}



{
 newValues[`spouse_name`] && (
    <React.Fragment>


<h2 style={{ fontWeight: "600" }}>Spouse Details <span><Button variant="content" style={{ float: "right" }} ></Button></span></h2>
 <FormControlLabel
    control={
        <Checkbox
            checked={beneficiaryInputs.isSelected} // Assuming you have a state to control this
            onChange={(e) => handleChangeBeneficiaryInputSpouse(e, "isSelected")} // Adjust your handling function
            name="Beneficiary_spouse"
            color="secondary"
        />
    }
    label=""
    style={{ margin: "normal", marginRight: "10px" }}
/>
                <TextField
                     placeholder="Relation"
                    margin="normal"
                    variant="outlined"
                    color="secondary"
                    style={{ width: "500px", marginRight: "10px" }}
                    value="Spouse"

                    // onChange={(e) => handleChangeBeneficiaryInput(e, "age")}
                />
                 <TextField
                    placeholder="Spouse Name"
                    value={newValues.spouse_name}
                    margin="normal"
                    variant="outlined"
                    color="secondary"
                    style={{ width: "500px",marginRight: "10px" }}
                    onChange={(e) => handleChangeBeneficiaryInput(e, "age")}
                />

</React.Fragment>
    )
    
}




{
  [1, 2,3, 4, 5].map((siblingNumber) => (
    newValues[`sibling_${siblingNumber}`] && (
      <React.Fragment key={siblingNumber}>
        <h2 style={{ fontWeight: "600" }}>
          Sibling {siblingNumber} Details{" "}
          <span>
            <Button variant="content" style={{ float: "right" }}></Button>
          </span>
        </h2>
        <FormControlLabel
          control={
            <Checkbox
              checked={beneficiarySibling.isSelected} // Assuming you have a state to control this
              onChange={(e) => handleChangeBeneficiaryInputSibling(e, "isSelected")} // Adjust your handling function
              name={`Beneficiary_sibling_${siblingNumber}`}
              color="secondary"
            />
          }
          label=""
          style={{ margin: "normal", marginRight: "10px" }}
        />
        <TextField
          placeholder="Relation"
          margin="normal"
          variant="outlined"
          color="secondary"
          style={{ width: "500px", marginRight: "10px" }}
          value={newValues[`sibling_${siblingNumber}`]}
          onChange={(e) => handleChangeBeneficiaryInput(e, "age")}
        />
        <TextField
          placeholder="Sibling Name"
          margin="normal"
          variant="outlined"
          color="secondary"
          style={{ width: "500px",marginRight: "10px" }}
          value={newValues[`sibling_${siblingNumber}_name`]}
          onChange={(e) => handleChangeBeneficiaryInput(e, "age")}
      />

</React.Fragment>

    )
))
}


{
  [1, 2,3, 4, 5].map((childrenNumber) => (
    newValues[`children_${childrenNumber}`] && (
      <React.Fragment key={childrenNumber}>
              <h2 style={{ fontWeight: "600" }}>Children {childrenNumber} Details <span><Button variant="content" style={{ float: "right" }} ></Button></span></h2>
<FormControlLabel
    control={
        <Checkbox
            checked={beneficiaryChildren.isSelected} // Assuming you have a state to control this
            onChange={(e) => handleChangeBeneficiaryInputChildren(e, "isSelected")} // Adjust your handling function
            name={`Beneficiary_children_${childrenNumber}`}
            color="secondary"
        />
    }
    label=""
    style={{ margin: "normal", marginRight: "10px" }}
/>
                <TextField
                    // label="Children Name" newValues[`children_${childrenNumber}`]
                    placeholder="Relation"
                    margin="normal"
                    variant="outlined"
                    color="secondary"
                    style={{ width: "500px", marginRight: "10px" }}
                    value={newValues[`children_${childrenNumber}`]}
                    onChange={(e) => handleChangeBeneficiaryInput(e, "age")}
                />
                 <TextField
                    // label="Children Age"
                    placeholder="children's Name"
                    margin="normal"
                    variant="outlined"
                    color="secondary"
                    style={{ width: "500px",marginRight: "10px" }}
                    value={newValues[`children_${childrenNumber}_name`]}
                    onChange={(e) => handleChangeBeneficiaryInput(e, "age")}
                />



</React.Fragment>

    )
))
}



                {beneficiaries.map((beneficiary, index) => (
                    <BeneficiaryFields
                        key={index}
                        index={index}
                        beneficiary={beneficiary}
                        handleChangeBeneficiary={() => {}}
                        handleDeleteBeneficiary={handleDeleteBeneficiary}
                    />
                ))}
            </div>
        </>
    );
}