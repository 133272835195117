import React, { useState } from "react";
import { TextField, Select, MenuItem, InputLabel, FormControl } from "@mui/material";




export default function FirstStep({firstValues}) {

    const [gender, setgender] = React.useState('');
    const [marriage, setMarriage] = React.useState('');
    const [will, setWill] = React.useState('');
    const [dob, setDob] = React.useState('');
    const [religion, setReligion] = React.useState('');
    const [occupation, setOccupation] = React.useState('');
    const [formData, setFormData] = useState({})

    firstValues(formData);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value })
    };


    const handleChangeGender = (event) => {
        const { name, value } = event.target;
        setgender(event.target.value);
        setFormData({ ...formData, [name]: value })
    };
    const handleChangeMarriage = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value })

    };
    const handleChangeWill = (event) => {
        const { name, value } = event.target;
        setWill(event.target.value);
        setFormData({ ...formData, [name]: value })

    }

    const handleChangeReligion = (event) => {
        const { name, value } = event.target;
        setReligion(event.target.value);
        setFormData({ ...formData, [name]: value })

    };

    const handleDobChange = (event) => {
        const { name, value } = event.target;
        setDob(event.target.value);
        setFormData({ ...formData, [name]: value })

    };

    const handleChangeOccupation = (event) => {
        const { name, value } = event.target;
        setOccupation(event.target.value);
        setFormData({ ...formData, [name]: value })
    }


    return (
        <>
      <h1> Welcome ! Let's quickly get to know you</h1> 
            <div style={{ margin: "10px" }}>


                <TextField label="FullName" name="fullName" margin="normal" variant="outlined" 
                 onChange={handleChange}
                color="secondary"required style={{ width: "520px", marginRight: "10px" }}></TextField>


<TextField label="" type="date" margin="normal" name="dob" variant="outlined" color="secondary" onChange={handleDobChange} style={{ width: "520px", marginRight: "10px" }} />



                <FormControl style={{ marginTop: "8px" }}>

                    <InputLabel id="gender">Gender</InputLabel>
                    <Select
                        labelId="gender"
                        id="demo-simple-select"
                        value={gender}
                        name="gender"
                        label="gender"
                        onChange={handleChangeGender}
                        color="secondary" style={{ width: "520px", marginRight: "10px" }}
                    >
                        <MenuItem value={"male"}>Male</MenuItem>
                        <MenuItem value={"female"}>Female</MenuItem>
                        <MenuItem value={"other"}>Other</MenuItem>
                    </Select>
                </FormControl>



                <FormControl style={{ marginTop: "16px" }}>
                    <InputLabel id="religion">Select Religion</InputLabel>
                    <Select
                        labelId="religion"
                        id="demo-simple-select"
                        name="religion"
                        value={religion}
                        label="gender"
                        onChange={handleChangeReligion}
                        color="secondary" style={{ width: "520px", marginRight: "10px" }}
                    >
                        <MenuItem value={"Hindu"}>Hindu</MenuItem>
                        <MenuItem value={"Sikh"}>Sikh</MenuItem>
                        <MenuItem value={"Jain"}>Jain</MenuItem>
                        <MenuItem value={"Indian Christian"}>Indian Christian</MenuItem>
                        <MenuItem value={"Parsi"}>Parsi</MenuItem>
                        <MenuItem value={"Buddist"}>Buddist</MenuItem>
                        <MenuItem value={"Jews"}>Jews</MenuItem>
                        <MenuItem value={"Muslim"}>Muslim</MenuItem>
                    </Select>
                </FormControl>



                <FormControl style={{ marginTop: "8px" }}>

                    <InputLabel id="MaritarialStatus">Maritarial Status</InputLabel>
                    <Select
                        labelId="MaritarialStatus"
                        name="maritarialStatus"
                        id="demo-simple-select"
                        label="MaritarialStatus"
                        onChange={handleChange}
                        color="secondary" style={{ width: "520px", marginRight: "10px" }}
                    >
                        <MenuItem value={"Married"}>Married</MenuItem>
                        <MenuItem value={"Widow"}>Widow</MenuItem>
                        <MenuItem value={"Divorced"}>Divorced</MenuItem>
                        <MenuItem value={"Unmarried"}>Unmarried</MenuItem>
                    </Select>
                </FormControl>



                <FormControl style={{ marginTop: "16px" }}>
                    <InputLabel id="occupation">Occupation</InputLabel>
                    <Select
                        labelId="occupation"
                        id="demo-simple-select"
                        value={occupation}
                        name="occupation"
                        label="Occupation"
                        onChange={handleChangeOccupation}
                        color="secondary" style={{ width: "520px", marginRight: "10px" }}
                    >
                        <MenuItem value={"Self Employed"}>Self Employed</MenuItem>
                        <MenuItem value={"Service"}>Service</MenuItem>
                        <MenuItem value={"Business"}>Business</MenuItem>
                        <MenuItem value={"Home Maker"}>Home Maker</MenuItem>
                        <MenuItem value={"Student"}>Student</MenuItem>
                        <MenuItem value={"Retired"}>Retired</MenuItem>
                        <MenuItem value={"Other"}>Other</MenuItem>
                    </Select>
                </FormControl>




                <TextField label="Address" name="address" margin="normal"  onChange={handleChange}
                variant="outlined" color="secondary" style={{ width: "520px", marginRight: "10px" }}></TextField>
                <TextField label="Pincode" margin="normal" 
                name="pincode"
                variant="outlined"  onChange={handleChange}
                color="secondary" style={{ width: "520px" }}></TextField>

                
            </div>

        </>
    )
}