import React from "react";
import "./will.css";

function Will({ newData }) {
  console.log("newData", newData);

  const [ageNew, setAgeNew] = React.useState(0);
  const [formattedDate, setFormattedDate] = React.useState(0);
  const [dayOfWeek, setDayOfWeek] = React.useState(0);

  const handlePrint = () => {
    // const button= document.getElementsByClassName('saveToPDF')[0];
    // button.style.display = 'none';
    window.print();
  };


  return (
    <>
      <div className="will">
        <h4>
          <u> Will </u>
        </h4>
        <p>
          This is my last will and testament. I {newData.fullName}&nbsp; S/o{" "}
          {newData.father_name} aged About {newData.age} years, presently
          residing {newData.address} ,in sound disposing mind and without
          any pressure from any person do hereby make this will as my last will
          and cancel all my previous wills and codicils to avoid any dispute or
          difference regarding my moveable and immovable properties after my
          death. I am blessed with all my below-mentioned legal heirs are well settled in their
          respective lives and they have looked after me very well.
        </p>
        <br />
        <br />

        <table>
          <thead>
            <tr>
              <th>Beneficiary</th>
              <th>Relation</th>
              <th>Share</th>
            </tr>
          </thead>
          <tbody>
            {newData.Beneficiary_father && (
              <tr>
                <td>{newData.father_name}</td>
                <td>Father</td>
                <td>{newData.share_father}</td>
              </tr>
            )}

            {newData.Beneficiary_mother && (
              <tr>
                <td>{newData.mother_name}</td>
                <td>Father</td>
                <td>{newData.share_mother}</td>
              </tr>
            )}

            {newData.Beneficiary_spouse && (
              <tr>
                <td>{newData.spouse_name}</td>
                <td>Father</td>
                <td>{newData.share_spouse}</td>
              </tr>
            )}

            {[1, 2, 3, 4, 5].map(
              (siblingNumber) =>
                newData[`Beneficiary_sibling_${siblingNumber}`] && (
                  <React.Fragment>
                    <tr>
                      <td>{newData[`sibling_${siblingNumber}_name`]}</td>
                      <td>{newData[`sibling_${siblingNumber}`]}</td>
                      <td>{newData[`share_sibling_${siblingNumber}`]}</td>
                    </tr>
                  </React.Fragment>
                )
            )}

            {[1, 2, 3, 4, 5].map(
              (childrenNumber) =>
                newData[`Beneficiary_children_${childrenNumber}`] && (
                  <React.Fragment>
                    <tr>
                      <td>{newData[`children_${childrenNumber}_name`]}</td>
                      <td>{newData[`children_${childrenNumber}`]}</td>
                      <td>{newData[`share_children_${childrenNumber}`]}</td>
                    </tr>
                  </React.Fragment>
                )
            )}
          </tbody>
        </table>
        <br />
        <br />
        <p>I am the owner and in possession of</p>
        <br />
        <br />
        <table>
          <tbody>
            {newData.jewellery && (
              <tr>
                <th>Jewellery</th>
                <td>{newData.jewellery_details}</td>
              </tr>
            )}

            {newData.artifacts_antiques && (
              <tr>
                <th>Artifacts_Antiques</th>
                <td>{newData.artifacts_antiques_details}</td>
              </tr>
            )}

            {newData.vehicle && (
              <tr>
                <th>Vehicle</th>
                <td>{newData.vehicle_details}</td>
              </tr>
            )}

            {newData.bank_account && (
              <tr>
                <th>Bank Account</th>
                <td>{newData.bank_account_details}</td>
              </tr>
            )}

            {newData.fixed_deposit && (
              <tr>
                <th>Fixed Deposit</th>
                <td>{newData.fixed_deposit_details}</td>
              </tr>
            )}

            {newData.lockers && (
              <tr>
                <th>Lockers</th>
                <td>{newData.lockers_details}</td>
              </tr>
            )}

            {newData.mutual_funds && (
              <tr>
                <th>Mutual Funds</th>
                <td>{newData.mutual_funds_details}</td>
              </tr>
            )}

            {newData.PPF && (
              <tr>
                <th>PPF</th>
                <td>{newData.PPF_details}</td>
              </tr>
            )}

            {newData.PF && (
              <tr>
                <th>PF</th>
                <td>{newData.PF_details}</td>
              </tr>
            )}

            {newData.gratuity && (
              <tr>
                <th>Gratuity</th>
                <td>{newData.gratuity_details}</td>
              </tr>
            )}

            {newData.Life_insurance_policy && (
              <tr>
                <th>Life Insurance Policy</th>
                <td>{newData.Life_insurance_policy_details}</td>
              </tr>
            )}

            {newData.Equity_shares_debentures_commodity && (
              <tr>
                <th>Equity/Shares/Debentures/Commodity</th>
                <td>{newData.Equity_shares_debentures_commodity_details}</td>
              </tr>
            )}

            {newData.crypto_currency && (
              <tr>
                <th>Crypto Currency</th>
                <td>{newData.crypto_currency_details}</td>
              </tr>
            )}

            {newData.NSC && (
              <tr>
                <th>NSC</th>
                <td>{newData.NSC_details}</td>
              </tr>
            )}

            {newData.others && (
              <tr>
                <th>Others</th>
                <td>{newData.others_details}</td>
              </tr>
            )}

            {newData.commercial_property && (
              <tr>
                <th>Commercial Property</th>
                <td>{newData.commercial_property_details}</td>
              </tr>
            )}

            {newData.residential_property && (
              <tr>
                <th>Residential Property</th>
                <td>{newData.residential_property_details}</td>
              </tr>
            )}
          </tbody>
        </table>
        <br />
        <br />
        <p>
          Life is uncertain and I do not know when the god calls me and I don’t
          know when I leave this beautiful world therefore, during my lifetime I
          want to make settlement of my all movable and immovable properties so
          as to avoid any difference or dispute over sharing of my properties
          among my legal heirs. Therefore, I am making the present will. So long
          I am alive I will continue to be owner of all my properties. However,
          after my death. (Testator should mention settlement/sharing
          ratio/arrangement with respect to his movable and immovable properties
          either in favor of legal heirs or any person of his choice)
          <br /> <br />I bequeath all my movable and immovable properties to my
          aforesaid legal heirs as per the arrangement made above. (I appoint
          Mr/Mrs {newData.executor_name} &nbsp; child of {" "}
          {newData.executor_child_of}.R/o {newData.executor_address}, and in
          case of his demise Mr/Mrs {newData.alternate_executor_name} child of {" "}
          {newData.alternate_executor_child_of} .R/o {newData.alternate_executor_address_of} as
          executor of This will) All my previous will and testament are hereby
          cancelled. Signed on {newData.weekDay} of {newData.formatDate} in the
          presence of the following witnesses who have also signed in presence
          of each other and in my presence.
        </p>
        <br /> <br />
        <br />
        <p>
          In Compliance of Section 32A of the Registration Act, 1908 <br />
          Name of the first party (Testator/ Testatrix)
        </p>
        <br />
        <br />
        <p>Left hand fingers impressions of Testator/ Testatrix</p>
        <div className="fingers">
          <div className="thumb">
            <h4>Thumb</h4>
            <div className="circle"></div>
          </div>

          <div className="index">
            <h4>Index</h4>
            <div className="circle"></div>
          </div>

          <div className="middle">
            <h4>Middle</h4>
            <div className="circle"></div>
          </div>

          <div className="ring">
            <h4>Ring</h4>
            <div className="circle"></div>
          </div>

          <div className="little">
            <h4>Little</h4>
            <div className="circle"></div>
          </div>
        </div>
        <br />
        <br />
        <p>Right hand fingers impressions of Testator/ Testatrix</p>
        <div className="fingers">
          <div className="thumb">
            <h4>Thumb</h4>
            <div class="circle"></div>
          </div>

          <div className="index">
            <h4>Index</h4>
            <div className="circle"></div>
          </div>

          <div className="middle">
            <h4>Middle</h4>
            <div className="circle"></div>
          </div>

          <div className="ring">
            <h4>Ring</h4>
            <div className="circle"></div>
          </div>

          <div className="little">
            <h4>Little</h4>
            <div className="circle"></div>
          </div>
        </div>
        <br />
        <p className="signature">Signature of Testator/ Testatrix</p>
        <br /> <br />
        <p>
          Certified that the above will has been signed by the above-mentioned
          testator in our presence and we have also signed as attesting
          witnesses in presence of the Testator/Testatrix and in the presence of
          each other after the contents of will explained to testator in Hindi
          (mention local language if any), he understood and agreed with the
          same on the date and time mentioned above.
        </p>
        <br />
        <br />
        Witnesses
        <br />

        <table>
          <thead>
            <tr>
              <th>Witness</th>
              <th>Name</th>
              <th>Address</th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <th>1</th>
              <th>{newData.witness_1_name}</th>
              <th>{newData.witness_1_address}</th>
            </tr>

            {newData.witness_2_name && (
              <tr>
                <th>2</th>
                <th>{newData.witness_2_name}</th>
                <th>{newData.witness_2_address}</th>
              </tr>
            )}


          </tbody>
        </table>
        <div className="saveToPDF">
          <button className="toPDF" onClick={handlePrint}>
            Download PDF
          </button>
        </div>
      </div>
    </>
  );
}

export default Will;
