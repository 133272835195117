import React, { useState } from 'react';

function CheckoutForm() {
  const [willPrice, setWillPrice] = useState(1000); // Initial price
  const [appliedCoupon, setAppliedCoupon] = useState(false); // Tracks if a coupon is applied
  const [couponCode, setCouponCode] = useState(''); // Coupon code state
  const [discount, setDiscount] = useState(0); // Discount amount

  // Calculate the price after discount
  const priceAfterDiscount = willPrice - discount;

  // Assuming GST is always 18% of the final price after discount
  const gst = priceAfterDiscount * 0.18;

  // Final price including GST
  const totalPrice = priceAfterDiscount + gst;

  // Dummy function to "apply" a coupon
  const applyCoupon = () => {
    if (couponCode === 'ActiveCoupon' && !appliedCoupon) {
      setDiscount(100);
      setAppliedCoupon(true);
    }
  };

  // Simple styles
  const styles = {
    container: {
      maxWidth: '600px',
      margin: '20px auto',
      padding: '20px',
      boxShadow: '0 0 10px rgba(0,0,0,0.1)',
      borderRadius: '8px',
    },
    header: {
      marginBottom: '20px',
    },
    billDetail: {
      marginBottom: '10px',
    },
    input: {
      marginRight: '10px',
      padding: '8px',
      border: '1px solid #ccc',
      borderRadius: '4px',
    },
    button: {
      padding: '8px 16px',
      border: 'none',
      borderRadius: '4px',
      backgroundColor: '#007bff',
      color: 'white',
      cursor: 'pointer',
    },
    total: {
      marginTop: '20px',
      fontWeight: 'bold',
    }
  };

  return (
    <div style={styles.container}>
      <h2 style={styles.header}>Checkout</h2>
      <div style={styles.billDetail}>Will Price(₹): {willPrice}</div>
      <div style={styles.billDetail}>Applied Coupon: {appliedCoupon ? 'Yes' : 'No'}</div>
      <div style={styles.billDetail}>
        <input 
          style={styles.input}
          type="text" 
          placeholder="Enter Coupon Code" 
          value={couponCode} 
          onChange={(e) => setCouponCode(e.target.value)}
        />
        <button onClick={applyCoupon} style={styles.button}>Apply Coupon</button>
      </div>
      <div>Bill Details</div>
      <div style={styles.billDetail}>Regular Price: {willPrice}₹</div>
      <div style={styles.billDetail}>Price After Discount: {priceAfterDiscount}₹</div>
      <div style={styles.total}>To Pay: (Includes GST 18%) {totalPrice.toFixed(2)}₹</div>
    </div>
  );
}

export default CheckoutForm;