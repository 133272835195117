import { TextField, FormControl, MenuItem, InputLabel, Select, Button } from "@mui/material";
import React, { useState, useEffect } from 'react';


export default function StepTwo({newValues, ninthValues}) {
    const [religion, setReligion] = React.useState('');
    const [religions, setReligions] = React.useState('');
    const [change, setChanges] = React.useState('');
    const [occupation, setOccupation] = React.useState('');
    const [children, setChildren] = React.useState([]);
    const [childrens, setChildrens] = React.useState([]);
    const [siblings, setSiblings] = React.useState([]);
    const [sibling, setSibling] = React.useState([]);
    const [vehicle, setVehicle] = React.useState([]);
    const [bank, setBank] = React.useState([]);
    const [deposites, setDeposites] = React.useState([]);
    const [lockers, setLocker] = React.useState([]);

    const [funds, setFunds] = React.useState([]);
    const [PPF, setPPF] = React.useState([]);
    const [PF, setPF] = React.useState([]);
    const [Grad, setGrad] = React.useState([]);

    const [lic, setLic] = React.useState([]);
    const [Equity, setEquity] = React.useState([]);
    const [crypto, setCrypto] = React.useState([]);
    const [nsc, setNsc] = React.useState([]);
    const [Others, setOthers] = React.useState([]);
    const [Property, setProperty] = React.useState([]);



    const [formData, setFormData] = useState({})
    ninthValues(formData);






    // const handleChangeReligion = (e) => {
    //     setReligion(e.target.value);
    // };

    // const handleReligions=(e)=>{
    //     setChanges(e.target.value);
    // }

   
    // const handleChangeReligions = (e) => {
    //     setReligions(e.target.value);
    // };

    // const handleChangeSibling = (e) => {
    //     setSibling(e.target.value);
    // };

    // const handleChangeChildren = (e) => {
    //     setChildrens(e.target.value);
    // };

 
    const handlecrypto = () => {
        setCrypto([...crypto, { name: "", lname: "" }]);
    };

    const handleNSC = () => {
        setNsc([...nsc, { name: "", lname: "" }]);
    };
    const handlecr = (e, i, type) => {
        const { name, value } = e.target;
        console.log(`Handling change for: ${name} with value: ${value}`);
        const onChangeVal = [...(type === "crypto" ? crypto : nsc)];
        onChangeVal[i][name] = value;
        console.log(onChangeVal);
        type === "crypto" ? setCrypto(onChangeVal) : setNsc(onChangeVal);
    };


    useEffect(() => {
        console.log(crypto);
    }, [crypto]);
    
    useEffect(() => {
        console.log(nsc);
    }, [nsc]);
    
    const deletecr = (i, type) => {
        const deleteValue = [...(type === "crypto" ? crypto : nsc)];
        deleteValue.splice(i, 1);
        type === "crypto" ? setCrypto(deleteValue) : setNsc(deleteValue);
    }
 
    const handleothers = () => {
        setOthers([...Others, { name: "", lname: "" }]);
    };

    const handleproperty = () => {
        setProperty([...Property, { name: "", lname: "" }]);
    };
    const handles= (e, i, type) => {
        const { name, value } = e.target;
        console.log(`Handling change for: ${name} with value: ${value}`);
        const onChangeVal = [...(type === "" ? Others : Property)];
        onChangeVal[i][name] = value;
        console.log(onChangeVal);
        type === "Property" ? setProperty(onChangeVal) : setOthers(onChangeVal);
    };


    useEffect(() => {
        console.log(Others);
    }, [Others]);
    
    useEffect(() => {
        console.log(Property);
    }, [Property]);
    
    const deletes = (i, type) => {
        const deleteValue = [...(type === "Property" ? Property : Others)];
        deleteValue.splice(i, 1);
        type === "Property" ? setOthers(deleteValue) : setProperty(deleteValue);
    }



    // const handleChangeOccupation = (e) => {
    //     setOccupation(e.target.value);
    // }

    const handleSibling = () => {
        setSiblings([...siblings, { name: "", lname: "" }]);
    };

    const handleChildren = () => {
        setChildren([...children, { name: "", lname: "" }]);
    };
   

    useEffect(() => {
        console.log(siblings);
    }, [siblings]);
   
    useEffect(() => {
        console.log(children);
    }, [children]);
   
    const deleteItem = (i, type) => {
        const deleteValue = [...(type === "children" ? children : siblings)];
        deleteValue.splice(i, 1);
        type === "children" ? setChildren(deleteValue) : setSiblings(deleteValue);
    }




   
    const handleVehicle = () => {
        setVehicle([...vehicle, { name: "", lname: "" }]);
    };

  
    const handleChanges = (e, i, type) => {
        const { name, value } = e.target;
        console.log(`Handling change for: ${name} with value: ${value}`);
        const onChangeVal = [...(type === "vehicle" ? vehicle : bank)];
        onChangeVal[i][name] = value;
        console.log(onChangeVal);
        type === "bank" ? setBank(onChangeVal) : setVehicle(onChangeVal);


    };

    const handleBank = () => {
        setBank([...bank, { name: "", lname: "" }]);
    };

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData({...formData, [name]: value})
        console.log('formData', formData);
    };




    useEffect(() => {
        console.log(vehicle);
    }, [vehicle]);
   
    useEffect(() => {
        console.log(bank);
    }, [bank]);
   
    const deleteItems = (i, type) => {
        const deleteValue = [...(type === "vehicle" ? vehicle : bank)];
        deleteValue.splice(i, 1);
        type === "bank" ? setBank(deleteValue) : setVehicle(deleteValue);
    }


    const handleDeposites = () => {
        setDeposites([...deposites, { name: "", lname: "" }]);
    };

    const handlelockers = () => {
        setLocker([...lockers, { name: "", lname: "" }]);
    };
    const handleChangess = (e, i, type) => {
        const { name, value } = e.target;
        console.log(`Handling change for: ${name} with value: ${value}`);
        const onChangeVal = [...(type === "deposites" ? deposites : lockers)];
        onChangeVal[i][name] = value;
        console.log(onChangeVal);
        type === "lockers" ? setLocker(onChangeVal) : setDeposites(onChangeVal);
    };


    useEffect(() => {
        console.log(deposites);
    }, [deposites]);
   
    useEffect(() => {
        console.log(lockers);
    }, [lockers]);
   
    const deleteItemss = (i, type) => {
        const deleteValue = [...(type === "deposites" ? deposites : lockers)];
        deleteValue.splice(i, 1);
        type === "lockers" ? setLocker(deleteValue) : setDeposites(deleteValue);
    }
   

   
    const handleFunds = () => {
        setFunds([...funds, { name: "", lname: "" }]);
    };

    const handlePPF = () => {
        setPPF([...PPF, { name: "", lname: "" }]);
    };
    const handleChanging = (e, i, type) => {
        const { name, value } = e.target;
        console.log(`Handling change for: ${name} with value: ${value}`);
        const onChangeVal = [...(type === "funds" ? funds : PPF)];
        onChangeVal[i][name] = value;
        console.log(onChangeVal);
        type === "PPF" ? setPPF(onChangeVal) : setFunds(onChangeVal);
    };


    useEffect(() => {
        console.log(funds);
    }, [funds]);
   
    useEffect(() => {
        console.log(PPF);
    }, [PPF]);
   
    const deleteItemsss = (i, type) => {
        const deleteValue = [...(type === "funds" ? funds : PPF)];
        deleteValue.splice(i, 1);
        type === "PPF" ? setPPF(deleteValue) : setFunds(deleteValue);
    }



    const handleLIC = () => {
        setLic([...lic, { name: "", lname: "" }]);
    };

    const handleEQUITY = () => {
        setEquity([...Equity, { name: "", lname: "" }]);
    };
    const handle = (e, i, type) => {
        const { name, value } = e.target;
        console.log(`Handling change for: ${name} with value: ${value}`);
        const onChangeVal = [...(type === "lic" ? lic : Equity)];
        onChangeVal[i][name] = value;
        console.log(onChangeVal);
        type === "equity" ? setEquity(onChangeVal) : setLic(onChangeVal);
    };


    useEffect(() => {
        console.log(Equity);
    }, [Equity]);
   
    useEffect(() => {
        console.log(lic);
    }, [lic]);
   
    const deleteLIC = (i, type) => {
        const deleteValue = [...(type === "lic" ? lic : Equity)];
        deleteValue.splice(i, 1);
        type === "Equity" ? setEquity(deleteValue) : setLic(deleteValue);
    }

   
    const handlePF = () => {
        setPF([...PF, { name: "", lname: "" }]);
    };

    const handleGartuity = () => {
        setGrad([...Grad, { name: "", lname: "" }]);
    };
    const handleChan = (e, i, type) => {
        const { name, value } = e.target;
        console.log(`Handling change for: ${name} with value: ${value}`);
        const onChangeVal = [...(type === "PF" ? PF : Grad)];
        onChangeVal[i][name] = value;
        console.log(onChangeVal);
        type === "PF" ? setPF(onChangeVal) : setGrad(onChangeVal);
    };


    useEffect(() => {
        console.log(PF);
    }, [PF]);
   
    useEffect(() => {
        console.log(Grad);
    }, [Grad]);
   
    const deletePFS = (i, type) => {
        const deleteValue = [...(type === "PF" ? PF : Grad)];
        deleteValue.splice(i, 1);
        type === "Grad" ? setGrad(deleteValue) : setPF(deleteValue);
    }


    return (
        <>
            {/* <h1>Let's get to know your family</h1> */}
            <div style={{ margin: "10px" }}>
                {/* <TextField label="Father" margin="normal" variant="outlined" color="secondary" style={{ width: "300px", marginRight: "10px" }} />
                <TextField label="Mother" margin="normal" variant="outlined" color="secondary" style={{ width: "300px" }} /> */}
               

               {newValues["jewellery"] && (
              <React.Fragment>



                <h2 style={{ fontWeight: "600" }}>Jwellary<span><Button variant="content" style={{ float: "right",color:"green" }} onClick={handleSibling}>Add More Jwellary</Button></span></h2>
                <p>Description (You may mention details like gold / diamond / platinum / necklace , describe the jewellery.)</p>
             

                        <TextField label="Jwellary" margin="normal" variant="outlined" name="jewellery_details" color="secondary" style={{ width: "1050px",marginRight: "10px" }} onChange={(e) => handleChange(e,  "siblings")} />
                        {/* <TextField label="Age of the Sibling" margin="normal" variant="outlined" color="secondary" style={{ width: "350px", marginRight: "10px" }} onChange={(e) => handleChange(e, "siblings")} /> */}
                        {/* <Button variant="content" style={{ float: "right" }} onClick={() => deleteItem(i, "siblings")}>Delete Sibling</Button> */}
               
                {siblings.map((val, i) =>
                    <>
                    {/* <h3>Sibling Name</h3> */}
                   


                        <TextField
    name="name" // This should match the property name for the sibling's name
    // label="Name of the Sibling"
    placeholder="ENTER NOTES"
    margin="normal"
    variant="outlined"
    color="secondary"
    style={{ width: "1050px", marginRight: "10px" }}
    value={val.name} // Ensure this matches the state's structure
    onChange={(e) => handleChange(e, i, "siblings")}
/>

                        {/* label="Name  <TextFieldof the Sibling" margin="normal" variant="outlined" color="secondary" style={{ width: "350px",marginRight: "10px" }} onChange={(e) => handleChange(e, i, "siblings")} /> */}
                        {/* <TextField label="Age of the Sibling" margin="normal" variant="outlined" color="secondary" style={{ width: "350px", marginRight: "10px" }} onChange={(e) => handleChange(e, i, "siblings")} /> */}
                        <Button variant="content" style={{ float: "right", color:"red" }} onClick={() => deleteItem(i, "siblings")}>Remove Row</Button>
                    </>
                )}


</React.Fragment>
            )}



               <br></br>
               <br></br>

 {newValues["artifacts_antiques"] && (
              <React.Fragment>

                <h2 style={{ fontWeight: "600" }}>Artifacts/ Antiques <span><Button variant="content" style={{ float: "right"}} onClick={handleChildren}>Add More Artifacts/ Antiques</Button></span></h2>
             <p>Description (Please describe the antiques e.g. vintage chandelier , marble statue of peacock, etc.)</p>
               

                        <TextField placeholder="Enter Your Notes" margin="normal" name="artifacts_antiques_details" variant="outlined" color="secondary" style={{ width: "1050px" ,marginRight: "10px"}} onChange={handleChange} />
                        {/* <TextField label="Age of the Children"(e) => handleChange(e, "children") margin="normal" variant="outlined" color="secondary" style={{ width: "350px", marginRight: "10px" }} onChange={(e) => handleChange(e, "children")} /> */}
                        {/* <Button variant="content" style={{ float: "right" }} onClick={() => deleteItem( "children")}>Delete Children</Button> */}
                {children.map((val, i) =>
                    <>
               

                        <TextField placeholder="Enter Notes" margin="normal" variant="outlined" color="secondary" style={{ width: "1050px",marginRight: "10px" }} onChange={(e) => handleChange(e, i, "siblings")} />
                        {/* <TextField label="Age of the Sibling" margin="normal" variant="outlined" color="secondary" style={{ width: "350px", marginRight: "10px" }} onChange={(e) => handleChange(e, i, "siblings")} /> */}
                        <Button variant="content" style={{ float: "right" }} onClick={() => deleteItem(i, "children")}>Remove Row</Button>
                    </>
                )}


</React.Fragment>
            )}



{newValues["vehicle"] && (
              <React.Fragment>

                <h2 style={{ fontWeight: "600" }}>Vehicle <span><Button variant="content" style={{ float: "right"}} onClick={handleVehicle}>Add More Vehicle</Button></span></h2>
               <p>Description (You may mention vehicle make, vehical type (car /scooter, SUV,etc), vehicle number.)</p>
               

                <TextField placeholder="Enter Your Notes" margin="normal" variant="outlined" name="vehicle_details"  color="secondary" style={{ width: "1050px" ,marginRight: "10px"}} onChange={(e) => handleChanges(e, "vehicle")} />
                {/* <TextField label="Age of the Children" margin="normal" variant="outlined" color="secondary" style={{ width: "350px", marginRight: "10px" }} onChange={(e) => handleChange(e, "children")} /> */}
                {/* <Button variant="content" style={{ float: "right" }} onClick={() => deleteItem( "children")}>Delete Children</Button> */}
        {vehicle.map((val, i) =>
            <>
       
                <TextField placeholder="Enter Notes" margin="normal" variant="outlined" color="secondary" style={{ width: "1050px",marginRight: "10px" }} onChange={(e) => handleChanges(e, i, "vehicle")} />

                {/* <TextField label="Age of the Sibling" margin="normal" variant="outlined" color="secondary" style={{ width: "350px", marginRight: "10px" }} onChange={(e) => handleChange(e, i, "siblings")} /> */}
                <Button variant="content" style={{ float: "right" }} onClick={() => deleteItems(i, "vehicle")}>Remove Row</Button>
            </>
        )}


</React.Fragment>
            )}



{newValues["bank_account"] && (
              <React.Fragment>

        <h2 style={{ fontWeight: "600" }}>Bank account <span><Button variant="content" style={{ float: "right"}} onClick={handleBank}>Add More Bank Account</Button></span></h2>
     <p>   Description (You may mention bank and branch name, customer id, account numbers. Do NOT mention CVV, passwords, OTP, etc.) </p>    
               

                <TextField placeholder="Enter Your Notes" margin="normal" variant="outlined" name="bank_account_details" color="secondary" style={{ width: "1050px" ,marginRight: "10px"}} onChange={handleChange} />
                {/* <TextField label="Age of the Children" margin="normal" variant="outlined" color="secondary" style={{ width: "350px", marginRight: "10px" }} onChange={(e) => handleChange(e, "children")} /> */}
                {/* <Button variant="content" style={{ float: "right" }} onClick={() => deleteItem( "children")}>Delete Children</Button> */}
        {bank.map((val, i) =>
            <>
       
                <TextField placeholder="Enter Notes" margin="normal" variant="outlined" color="secondary" style={{ width: "1050px",marginRight: "10px" }} onChange={handleChange} />

                {/* <TextField label="Age of the Sibling" margin="normal" variant="outlined" color="secondary" style={{ width: "350px", marginRight: "10px" }} onChange={(e) => handleChange(e, i, "siblings")} /> */}
                <Button variant="content" style={{ float: "right" }} onClick={() => deleteItems(i, "bank")}>Remove Row</Button>
            </>
        )}

</React.Fragment>
)}



{newValues["fixed_deposit"] && (
              <React.Fragment>
        <h2 style={{ fontWeight: "600" }}>Fixed Deposits <span><Button variant="content" style={{ float: "right"}} onClick={handleDeposites}>Add More Fixed Deposite</Button></span></h2>
          <p>Description (You may mention bank and branch name, customer id, fixed deposit numbers, amount. Do NOT mention CVV, passwords, OTP, etc.)</p>      
               

                <TextField placeholder="Enter Your Notes" margin="normal" variant="outlined" name="fixed_deposit_details"  color="secondary" style={{ width: "1050px" ,marginRight: "10px"}} onChange={handleChange} />
                {/* <TextField label="Age of the Children" margin="normal" variant="outlined" color="secondary" style={{ width: "350px", marginRight: "10px" }} onChange={(e) => handleChange(e, "children")} /> */}
                {/* <Button variant="content" style={{ float: "right" }} onClick={() => deleteItem( "children")}>Delete Children</Button> */}
        {deposites.map((val, i) =>
            <>
       
                <TextField placeholder="Enter Notes" margin="normal" variant="outlined" color="secondary" style={{ width: "1050px",marginRight: "10px" }} onChange={(e) => handleChangess(e, i, "deposites")} />

                {/* <TextField label="Age of the Sibling" margin="normal" variant="outlined" color="secondary" style={{ width: "350px", marginRight: "10px" }} onChange={(e) => handleChange(e, i, "siblings")} /> */}
                <Button variant="content" style={{ float: "right" }} onClick={() => deleteItemss(i, "deposites")}>Remove Row</Button>
            </>
        )}
       
       </React.Fragment>
)}





{newValues["lockers"] && (
              <React.Fragment>

        <h2 style={{ fontWeight: "600" }}>Lockers <span><Button variant="content" style={{ float: "right"}} onClick={handlelockers}>Add More Artifacts/ Antiques</Button></span></h2>
         <p>Description (You may mention bank /company and branch name, customer id, locker numbers.)</p>      
               

                <TextField placeholder="Enter Your Notes" margin="normal" variant="outlined" name="lockers_details"   color="secondary" style={{ width: "1050px" ,marginRight: "10px"}} onChange={handleChange} />
                {/* <TextField label="Age of the Children" margin="normal" variant="outlined" color="secondary" style={{ width: "350px", marginRight: "10px" }} onChange={(e) => handleChange(e, "children")} /> */}
                {/* <Button variant="content" style={{ float: "right" }} onClick={() => deleteItem( "children")}>Delete Children</Button> */}
        {lockers.map((val, i) =>
            <>
       
                <TextField placeholder="Enter Notes" margin="normal" variant="outlined" color="secondary" style={{ width: "1050px",marginRight: "10px" }} onChange={(e) => handleChangess(e, i, "lockers")} />

                {/* <TextField label="Age of the Sibling" margin="normal" variant="outlined" color="secondary" style={{ width: "350px", marginRight: "10px" }} onChange={(e) => handleChange(e, i, "siblings")} /> */}
                <Button variant="content" style={{ float: "right" }} onClick={() => deleteItemss(i, "lockers")}>Remove Row</Button>
            </>
        )}

</React.Fragment>
)}




{newValues["mutual_funds"] && (
              <React.Fragment>

        <h2 style={{ fontWeight: "600" }}>Mutual funds <span><Button variant="content" style={{ float: "right"}} onClick={handleFunds}>Add More Artifacts/ Antiques</Button></span></h2>
           <p>Description (You may mention company name, fund name, portfolio numbers. Do NOT mention passwords, OTP, etc.)</p>    
               

                <TextField placeholder="Enter Your Notes" margin="normal" variant="outlined" name="mutual_funds_details"   color="secondary" style={{ width: "1050px" ,marginRight: "10px"}} onChange={handleChange} />
                {/* <TextField label="Age of the Children" margin="normal" variant="outlined" color="secondary" style={{ width: "350px", marginRight: "10px" }} onChange={(e) => handleChange(e, "children")} /> */}
                {/* <Button variant="content" style={{ float: "right" }} onClick={() => deleteItem( "children")}>Delete Children</Button> */}
        {funds.map((val, i) =>
            <>
       
                <TextField placeholder="Enter Notes" margin="normal" variant="outlined" color="secondary" style={{ width: "1050px",marginRight: "10px" }} onChange={(e) => handleChange(e, i, "funds")} />

                {/* <TextField label="Age of the Sibling" margin="normal" variant="outlined" color="secondary" style={{ width: "350px", marginRight: "10px" }} onChange={(e) => handleChange(e, i, "siblings")} /> */}
                <Button variant="content" style={{ float: "right" }} onClick={() => deleteItemsss(i, "funds")}>Remove Row</Button>
            </>
        )}
</React.Fragment>
)}



{newValues["PPF"] && (
              <React.Fragment>
        <h2 style={{ fontWeight: "600" }}>PPF<span><Button variant="content" style={{ float: "right"}} onClick={handlePPF}>Add More Artifacts/ Antiques</Button></span></h2>
               
            <p>Description (You may mention Post office / bank and branch name, customer id, PPF account number. Do NOT mention passwords, OTP, etc.)</p>  

                <TextField placeholder="Enter Your Notes" margin="normal" variant="outlined" name="PPF_details" color="secondary" style={{ width: "1050px" ,marginRight: "10px"}} onChange={handleChange} />
                {/* <TextField label="Age of the Children" margin="normal" variant="outlined" color="secondary" style={{ width: "350px", marginRight: "10px" }} onChange={(e) => handleChange(e, "children")} /> */}
                {/* <Button variant="content" style={{ float: "right" }} onClick={() => deleteItem( "children")}>Delete Children</Button> */}
        {PPF.map((val, i) =>
            <>
       
                <TextField placeholder="Enter Notes" margin="normal" variant="outlined" color="secondary" style={{ width: "1050px",marginRight: "10px" }} onChange={(e) => handleChange(e, i, "PPF")} />

                {/* <TextField label="Age of the Sibling" margin="normal" variant="outlined" color="secondary" style={{ width: "350px", marginRight: "10px" }} onChange={(e) => handleChange(e, i, "siblings")} /> */}
                <Button variant="content" style={{ float: "right" }} onClick={() => deleteItemsss(i, "PPF")}>Remove Row</Button>
            </>
        )}

</React.Fragment>)}




{newValues["PF"] && (
    <React.Fragment>

        <h2 style={{ fontWeight: "600" }}>PF<span><Button variant="content" style={{ float: "right"}} onClick={handlePF}>Add More Artifacts/ Antiques</Button></span></h2>
               
        <p>Description (You may mention employer name, employee number, PF account number. Do NOT mention passwords, OTP, etc.)</p>      

                <TextField placeholder="Enter Your Notes" margin="normal" variant="outlined" name="PF_details"  color="secondary" style={{ width: "1050px" ,marginRight: "10px"}} onChange={handleChange} />
                {/* <TextField label="Age of the Children" margin="normal" variant="outlined" color="secondary" style={{ width: "350px", marginRight: "10px" }} onChange={(e) => handleChange(e, "children")} /> */}
                {/* <Button variant="content" style={{ float: "right" }} onClick={() => deleteItem( "children")}>Delete Children</Button> */}
        {PF.map((val, i) =>
            <>
       
                <TextField placeholder="Enter Notes" margin="normal" variant="outlined" color="secondary" style={{ width: "1050px",marginRight: "10px" }} onChange={(e) => handleChan(e, i, "PF")} />

                {/* <TextField label="Age of the Sibling" margin="normal" variant="outlined" color="secondary" style={{ width: "350px", marginRight: "10px" }} onChange={(e) => handleChange(e, i, "siblings")} /> */}
                <Button variant="content" style={{ float: "right" }} onClick={() => deletePFS(i, "PF")}>Remove Row</Button>
            </>
        )}
</React.Fragment>)}


{newValues["gratuity"] && (
    <React.Fragment>

        <h2 style={{ fontWeight: "600" }}>Gratuity<span><Button variant="content" style={{ float: "right"}} onClick={handleGartuity}>Add More Artifacts/ Antiques</Button></span></h2>
            <p>Description (You may mention employer name, employee number, gratuity number. Do NOT mention passwords, OTP,etc.)
</p>    


                <TextField placeholder="Enter Your Notes" margin="normal" variant="outlined" name="gratuity_details"  color="secondary" style={{ width: "1050px" ,marginRight: "10px"}} onChange={handleChange} />
                {/* <TextField label="Age of the Children" margin="normal" variant="outlined" color="secondary" style={{ width: "350px", marginRight: "10px" }} onChange={(e) => handleChange(e, "children")} /> */}
                {/* <Button variant="content" style={{ float: "right" }} onClick={() => deleteItem( "children")}>Delete Children</Button> */}
        {Grad.map((val, i) =>
            <>
       
                <TextField placeholder="Enter Notes" margin="normal" variant="outlined" color="secondary" style={{ width: "1050px",marginRight: "10px" }} onChange={(e) => handleChan(e, i, "Grad")} />

                {/* <TextField label="Age of the Sibling" margin="normal" variant="outlined" color="secondary" style={{ width: "350px", marginRight: "10px" }} onChange={(e) => handleChange(e, i, "siblings")} /> */}
                <Button variant="content" style={{ float: "right" }} onClick={() => deletePFS(i, "Grad")}>Remove Row</Button>
            </>
        )}

</React.Fragment>)}


{newValues["Life_insurance_policy"] && (
    <React.Fragment>

        <h2 style={{ fontWeight: "600" }}>Life insurance Policy<span><Button variant="content" style={{ float: "right"}} onClick={handleLIC}>Add More Artifacts/ Antiques</Button></span></h2>
               
     <p>Description (You may mention company name from where policy is taken , policy name , policy number. Do NOT mention passwords, OTP, etc.)</p>

                <TextField placeholder="Enter Your Notes" margin="normal" variant="outlined" name="Life_insurance_policy_details" color="secondary" style={{ width: "1050px" ,marginRight: "10px"}} onChange={handleChange} />
                {/* <TextField label="Age of the Children" margin="normal" variant="outlined" color="secondary" style={{ width: "350px", marginRight: "10px" }} onChange={(e) => handleChange(e, "children")} /> */}
                {/* <Button variant="content" style={{ float: "right" }} onClick={() => deleteItem( "children")}>Delete Children</Button> */}
        {lic.map((val, i) =>
            <>
       
                <TextField placeholder="Enter Notes" margin="normal" variant="outlined" color="secondary" style={{ width: "1050px",marginRight: "10px" }} onChange={(e) => handle(e, i, "lic")} />

                {/* <TextField label="Age of the Sibling" margin="normal" variant="outlined" color="secondary" style={{ width: "350px", marginRight: "10px" }} onChange={(e) => handleChange(e, i, "siblings")} /> */}
                <Button variant="content" style={{ float: "right" }} onClick={() => deleteLIC(i, "lic")}>Remove Row</Button>
            </>
        )}

</React.Fragment>)}
       


{newValues["Equity_shares_debentures_commodity"] && (
    <React.Fragment>

<h2 style={{ fontWeight: "600" }}>Equity/shares/ debentures/commodity<span><Button variant="content" style={{ float: "right"}} onClick={handleEQUITY}>Add More Equity</Button></span></h2>
             <p>Description (You may mention demat and trading account details along with details of shares / debentures/ commodity, etc held.)</p>   
               

                <TextField placeholder="Enter Your Notes" margin="normal" variant="outlined" name="Equity_shares_debentures_commodity_details" color="secondary" style={{ width: "1050px" ,marginRight: "10px"}} onChange={handleChange} />
             
             
        {Equity.map((val, i) =>
            <>
        
                <TextField placeholder="Enter Notes" margin="normal" variant="outlined" color="secondary" style={{ width: "1050px",marginRight: "10px" }} onChange={(e) => handle(e, i, "Equity")} />

               
                <Button variant="content" style={{ float: "right" }} onClick={() => deleteLIC(i, "Equity")}>Remove Row</Button>
            </>
        )}


</React.Fragment>)}
       


{newValues["crypto_currency"] && (
    <React.Fragment>
       
        <h2 style={{ fontWeight: "600" }}><span>Crypto Currency
<Button variant="content" style={{ float: "right"}} onClick={handleChildren}>Add More Artifacts/ Antiques</Button></span></h2>
<p>Description (You may mention account and currency details.)  </p>        
               

                <TextField placeholder="Enter Your Notes" margin="normal" variant="outlined" name="crypto_currency_details" color="secondary" style={{ width: "1050px" ,marginRight: "10px"}} onChange={handleChange} />
                {/* <TextField label="Age of the Children" margin="normal" variant="outlined" color="secondary" style={{ width: "350px", marginRight: "10px" }} onChange={(e) => handleChange(e, "children")} /> */}
                {/* <Button variant="content" style={{ float: "right" }} onClick={() => deleteItem( "children")}>Delete Children</Button> */}
        {children.map((val, i) =>
            <>
       
                <TextField placeholder="Enter Notes" margin="normal" variant="outlined" color="secondary" style={{ width: "1050px",marginRight: "10px" }} onChange={(e) => handleChange(e, i, "siblings")} />

                {/* <TextField label="Age of the Sibling" margin="normal" variant="outlined" color="secondary" style={{ width: "350px", marginRight: "10px" }} onChange={(e) => handleChange(e, i, "siblings")} /> */}
                <Button variant="content" style={{ float: "right" }} onClick={() => deleteItem(i, "children")}>Remove Row</Button>
            </>
        )}
</React.Fragment>)}
       

       
{newValues["NSC"] && (
    <React.Fragment>

        <h2 style={{ fontWeight: "600" }}><span>NSC
<Button variant="content" style={{ float: "right"}} onClick={handleChildren}>Add More Artifacts/ Antiques</Button></span></h2>
<p>Description (You may mention post office / bank name and branch address, certifcate numbers and amounts.)</p>
               

                <TextField placeholder="Enter Your Notes" margin="normal" variant="outlined" name="NSC_details" color="secondary" style={{ width: "1050px" ,marginRight: "10px"}} onChange={handleChange} />
                {/* <TextField label="Age of the Children" margin="normal" variant="outlined" color="secondary" style={{ width: "350px", marginRight: "10px" }} onChange={(e) => handleChange(e, "children")} /> */}
                {/* <Button variant="content" style={{ float: "right" }} onClick={() => deleteItem( "children")}>Delete Children</Button> */}
        {children.map((val, i) =>
            <>
       
                <TextField placeholder="Enter Notes" margin="normal" variant="outlined" color="secondary" style={{ width: "1050px",marginRight: "10px" }} onChange={(e) => handleChange(e, i, "siblings")} />

                {/* <TextField label="Age of the Sibling" margin="normal" variant="outlined" color="secondary" style={{ width: "350px", marginRight: "10px" }} onChange={(e) => handleChange(e, i, "siblings")} /> */}
                <Button variant="content" style={{ float: "right" }} onClick={() => deleteItem(i, "children")}>Remove Row</Button>
            </>
        )}
       
       </React.Fragment>)}


       {newValues["others"] && (
    <React.Fragment>

        <h2 style={{ fontWeight: "600" }}><span>Others
<Button variant="content" style={{ float: "right"}} onClick={handleChildren}>Add More Artifacts/ Antiques</Button></span></h2>
                <p>Description (You may add as many rows as you want. Please do give descriptions.)</p>
               

                <TextField placeholder="Enter Your Notes" margin="normal" variant="outlined" name="others_details"  color="secondary" style={{ width: "1050px" ,marginRight: "10px"}} onChange={handleChange} />
                {/* <TextField label="Age of the Children" margin="normal" variant="outlined" color="secondary" style={{ width: "350px", marginRight: "10px" }} onChange={(e) => handleChange(e, "children")} /> */}
                {/* <Button variant="content" style={{ float: "right" }} onClick={() => deleteItem( "children")}>Delete Children</Button> */}
        {children.map((val, i) =>
            <>
       
                <TextField placeholder="Enter Notes" margin="normal" variant="outlined" color="secondary" style={{ width: "1050px",marginRight: "10px" }} onChange={(e) => handleChange(e, i, "siblings")} />

                {/* <TextField label="Age of the Sibling" margin="normal" variant="outlined" color="secondary" style={{ width: "350px", marginRight: "10px" }} onChange={(e) => handleChange(e, i, "siblings")} /> */}
                <Button variant="content" style={{ float: "right" }} onClick={() => deleteItem(i, "children")}>Remove Row</Button>
            </>
        )}
 </React.Fragment>)}




 {newValues["commercial_property"] && (
    <React.Fragment>
       
        <h2 style={{ fontWeight: "600" }}><span>Commercial Property
<Button variant="content" style={{ float: "right"}} onClick={handleChildren}>Add More Artifacts/ Antiques</Button></span></h2>
               
<p>Description (You may mention details like address, nearby landmark, area, usage (e.g. stationery shop).) </p>

                <TextField placeholder="Enter Your Notes" margin="normal" variant="outlined" name="commercial_property_details" color="secondary" style={{ width: (e) => handleChange(e, "children") ,marginRight: "10px"}} onChange={handleChange} />
               
        {children.map((val, i) =>
            <>
       
                <TextField placeholder="Enter Notes" margin="normal" variant="outlined" color="secondary" style={{ width: "1050px",marginRight: "10px" }} onChange={(e) => handleChange(e, i, "siblings")} />

                {/* <TextField label="Age of the Sibling" margin="normal" variant="outlined" color="secondary" style={{ width: "350px", marginRight: "10px" }} onChange={(e) => handleChange(e, i, "siblings")} /> */}
                <Button variant="content" style={{ float: "right" }} onClick={() => deleteItem(i, "children")}>Remove Row</Button>
            </>
        )}
         
         </React.Fragment>)}



         {newValues["residential_property"] && (
    <React.Fragment>
         
        <h2 style={{ fontWeight: "600" }}><span>Residential Property
<Button variant="content" style={{ float: "right"}} onClick={handleChildren}>Add More Artifacts/ Antiques</Button></span></h2>
               
<p>Description (You may mention details like address, nearby landmark, area.)</p>

                <TextField placeholder="Enter Your Notes" margin="normal" variant="outlined" name="residential_property_details" color="secondary" style={{ width: "1050px" ,marginRight: "10px"}} onChange={handleChange} />
                {/* <TextField label="Age of the Children" margin="normal" variant="outlined" color="secondary" style={{ width: "350px", marginRight: "10px" }} onChange={(e) => handleChange(e, "children")} /> */}
                {/* <Button variant="content" style={{ float: "right" }} onClick={() => deleteItem( "children")}>Delete Children</Button> */}
        {children.map((val, i) =>
            <>
       
                <TextField placeholder="Enter Notes" margin="normal" variant="outlined" color="secondary" style={{ width: "1050px",marginRight: "10px" }} onChange={(e) => handleChange(e, i, "siblings")} />
             
                {/* <TextField label="Age of the Sibling" margin="normal" variant="outlined" color="secondary" style={{ width: "350px", marginRight: "10px" }} onChange={(e) => handleChange(e, i, "siblings")} /> */}
                <Button variant="content" style={{ float: "right" }} onClick={() => deleteItem(i, "children")}>Remove Row</Button>
            </>
        )}

</React.Fragment>)}
       


            </div>
        </>
   
    )
}



