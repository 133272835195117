
import { Card, Container, Box } from '@mui/material';
import './App.css';
import CustomizedSteppers from './stepper/stepper.jsx';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import {useState, useEffect} from 'react';
import Will from './stepper/Will'
import axios from 'axios';
import logo from './logo1.png'
// import dotenv from 'dotenv'
// dotenv.config();

function App() {

  
  const [newData, setNewData] = useState({})

  useEffect(() => {
    async function fetchData() {
      const willify_id = localStorage.getItem("willify_id");
      console.log('willify_id', willify_id);

      if (willify_id) {
        try {
          const response = await axios.get(`http://app.willify.online/getDetails/?willify_id=${willify_id}`);
          console.log("response.data", response.data);
          setNewData(response.data);
          console.log("newData", newData);

        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }
    }

    fetchData();
  }, []); // Run only once on component mount




  return (
<>
 

<div style={{backgroundColor:"#71adee",height:"500%"}}>
<img src={logo} alt="Logo" style={{height:"80px"}}/>
         <div style={{backgroundColor:"#71adee",height:"500%"}}>
      <Container component={Box} p={4}>
        <Card component={Box} p={4}>
          {/* <CustomizedSteppers /> */}

          <Router>
   
   <Routes>
   
   <Route path="/" element={ <CustomizedSteppers />} />
       <Route path="/will" element={<Will newData={newData}/>} />
     </Routes>

</Router>




        </Card>
      </Container>
      </div>
      </div>

    
    </>

  );
}

export default App;