// import {
//   TextField,
//   FormControl,
//   MenuItem,
//   InputLabel,
//   Select,
//   Button,
// } from "@mui/material";
// import React, { useState } from "react";

// export default function StepTwo({ secondValues }) {
//   const [religion, setReligion] = React.useState("");
//   const [religions, setReligions] = React.useState('');
//   const [occupation, setOccupation] = React.useState("");
//   const [children, setChildren] = React.useState([]);
//   const [childrens, setChildrens] = React.useState([]);
//   const [siblings, setSiblings] = React.useState([]);
//   const [sibling, setSibling] = React.useState([]);

//   const [formData, setFormData] = useState({});
//   secondValues(formData);

//   const handleChangeReligion = (e) => {
//     setReligion(e.target.value);
//     const { name, value } = e.target;
//     setFormData({ ...formData, [name]: value });
//   };

//   const handleReligions=(e)=>{
//     // setChanges(e.target.value);
//     const { name, value } = e.target;
//     setFormData({ ...formData, [name]: value });
// }

// const handleChangeReligions = (e) => {
//     setReligions(e.target.value);
//     const { name, value } = e.target;
//     setFormData({ ...formData, [name]: value });
// };

//   const handleChangeSibling = (e) => {
//     setSibling(e.target.value);
//     const { name, value } = e.target;
//     setFormData({ ...formData, [name]: value });
//   };

//   const handleChangeChildren = (e) => {
//     setChildrens(e.target.value);
//     const { name, value } = e.target;
//     setFormData({ ...formData, [name]: value });
//   };

//   const handleSibling = () => {
//     setSiblings([...siblings, { name: "", lname: "" }]);
//   };

//   const handleChildren = () => {
//     setChildren([...children, { name: "", lname: "" }]);
//   };

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({ ...formData, [name]: value });
//   };

//   const deleteItem = (i, type) => {
//     const deleteValue = [...(type === "children" ? children : siblings)];
//     deleteValue.splice(i, 1);
//     type === "children" ? setChildren(deleteValue) : setSiblings(deleteValue);
//   };

//   return (
//     <>
//       <h1>Let's get to know your family</h1>
//       <div style={{ margin: "10px" }}>
//         <FormControl style={{ marginTop: "16px" }}>
//           <InputLabel id="religion"> Salutation</InputLabel>
//           <Select
//             labelId="religion"
//             id="demo-simple-select"
//             value={religion}
//             label="gender"
//             onChange={handleChangeReligion}
//             color="secondary"
//             style={{ width: "100px", marginRight: "10px" }}
//           >
//             <MenuItem value={"Mr"}>Mr</MenuItem>
//             <MenuItem value={"Dr"}>Dr</MenuItem>
//             <MenuItem value={"late"}>Late</MenuItem>
//           </Select>
//         </FormControl>

//         <TextField
//           label="Father Name"
//           margin="normal"
//           name="father_name"
//           onChange={handleChange}
//           variant="outlined"
//           color="secondary"
//           style={{ width: "520px", marginRight: "10px" }}
//         />

//         <TextField
//           label="Fathers Age"
//           name="father_age"
//           margin="normal"
//           variant="outlined"
//           color="secondary"
//           style={{ width: "470px" }}
//         />

//         <FormControl style={{ marginTop: "16px" }}>
//           <InputLabel id="religions"> Salutation</InputLabel>
//           <Select
//             labelId="religions"
//             id="demo-simple-select"
//             value={religions}
//             label="religions"
//             onChange={handleChangeReligions}
//             color="secondary"
//             style={{ width: "100px", marginRight: "10px" }}
//           >
//             <MenuItem value={"Mrs"}>Mrs</MenuItem>
//             <MenuItem value={"Dr"}>Dr</MenuItem>
//             <MenuItem value={"late"}>Late</MenuItem>
//           </Select>
//         </FormControl>

//         <TextField
//           label="Mother Name"
//           margin="normal"
//           name="mother_name"
//           onChange={handleChange}
//           variant="outlined"
//           color="secondary"
//           style={{ width: "520px" }}
//         />

//         <TextField
//           label="Mothers Age"
//           name="mother_age"
//           margin="normal"
//           variant="outlined"
//           color="secondary"
//           style={{ width: "470px" }}
//         />

//         <FormControl style={{ marginTop: "16px" }}>
//           <InputLabel id="change"> Salutation</InputLabel>
//           <Select
//             labelId="change"
//             id="demo-simple-select"
//             label="gender"
//             onChange={handleReligions}
//             color="secondary"
//             style={{ width: "100px", marginRight: "10px" }}
//           >
//             <MenuItem value={"Mrs"}>Mrs</MenuItem>
//             <MenuItem value={"Dr"}>Dr</MenuItem>
//             <MenuItem value={"late"}>Late</MenuItem>
//           </Select>
//         </FormControl>

//         <TextField
//           label="Spouse Name"
//           margin="normal"
//           name="spouse_name"
//           onChange={handleChange}
//           variant="outlined"
//           color="secondary"
//           style={{ width: "520px" }}
//         />

//         <TextField
//           label="Spouse Age"
//           margin="normal"
//           name="spouse_age"
//           variant="outlined"
//           color="secondary"
//           style={{ width: "470px" }}
//         />

//         <h2 style={{ fontWeight: "600" }}>
//           My Siblings Are
//           <span>
//             <Button
//               variant="content"
//               style={{ float: "right" }}
//               onClick={handleSibling}
//             >
//               Add Sibling
//             </Button>
//           </span>
//         </h2>

//         <FormControl style={{ marginTop: "16px" }}>
//           <InputLabel id="sibling">Sibling</InputLabel>
//           <Select
//             labelId="sibling"
//             id="demo-simple-select"
//             name="sibling-1"
//             value={sibling} // This seems incorrect
//             label="sibling"
//             onChange={handleChangeSibling} // This updates siblings to a non-array value
//             color="secondary"
//             style={{ width: "520px", marginRight: "10px" }}
//           >
//             {/* Menu items */}
//             <MenuItem value={"Select Sibling"}>Select Sibling</MenuItem>
//             <MenuItem value={"Brother"}>Brother</MenuItem>
//             <MenuItem value={"Sister"}>Sister</MenuItem>
//           </Select>
//         </FormControl>

//         <TextField
//           label="Name of the Sibling"
//           name="sibling-1-name"
//           margin="normal"
//           variant="outlined"
//           color="secondary"
//           style={{ width: "520px" }}
//           onChange={handleChange}
//         />
//         <TextField
//           label="Age of the Sibling"
//           name="sibling-1-age"
//           margin="normal"
//           variant="outlined"
//           color="secondary"
//           style={{ width: "520px", marginRight: "10px" }}
//           onChange={handleChange}
//         />
//         {/* <Button variant="content" style={{ float: "right" }} onClick={() => deleteItem(i, "siblings")}>Delete Sibling</Button> */}

//         {siblings.map((val, i) => (
//           <>
//             <FormControl style={{ marginTop: "16px" }}>
//               <InputLabel id="sibling">Select Sibling</InputLabel>
//               <Select
//                 labelId="sibling"
//                 name="sibling-2"
//                 id={`demo-simple-select-sibling-${i}`}
//                 value={val.name}
//                 label="gender"
//                 onChange={handleChange}
//                 color="secondary"
//                 style={{ width: "520px", marginRight: "10px" }}
//               >
//                 <MenuItem value={"Brother"}>Brother</MenuItem>
//                 <MenuItem value={"Sister"}>Sister</MenuItem>
//               </Select>
//             </FormControl>

//             <TextField
//               label="Name of the Sibling"
//               name="sibling-2-name"
//               margin="normal"
//               variant="outlined"
//               color="secondary"
//               style={{ width: "520px" }}
//               onChange={handleChange}
//             />
//             <TextField
//               label="Age of the Sibling"
//               name="sibling-2-age"
//               margin="normal"
//               variant="outlined"
//               color="secondary"
//               style={{ width: "520px", marginRight: "10px" }}
//               onChange={handleChange}
//             />
//             <Button
//               variant="content"
//               style={{ float: "right" }}
//               onClick={() => deleteItem(i, "siblings")}
//             >
//               Delete Sibling
//             </Button>
//           </>
//         ))}

//         <h2 style={{ fontWeight: "600" }}>
//           My children's Are{" "}
//           <span>
//             <Button
//               variant="content"
//               style={{ float: "right" }}
//               onClick={handleChildren}
//             >
//               Add Children
//             </Button>
//           </span>
//         </h2>

//         <FormControl style={{ marginTop: "16px" }}>
//           <InputLabel id="childrens">childrens</InputLabel>
//           <Select
//             labelId="childrens"
//             name="children-1"
//             id="demo-simple-select"
//             value={childrens} // This seems incorrect
//             label="childrens"
//             onChange={handleChangeChildren} // This updates siblings to a non-array value
//             color="secondary"
//             style={{ width: "520px", marginRight: "10px" }}
//           >
//             {/* Menu items */}
//             <MenuItem value={"Select Sibling"}>Select Children</MenuItem>
//             <MenuItem value={"Son"}>Son</MenuItem>
//             <MenuItem value={"Sister"}>Daughter</MenuItem>
//           </Select>
//         </FormControl>

//         <TextField
//           label="Name of the Children"
//           name="children-1-name"
//           margin="normal"
//           variant="outlined"
//           color="secondary"
//           style={{ width: "520px" }}
//           onChange={handleChange}
//         />
//         <TextField
//           label="Age of the Children"
//           name="children-1-age"
//           margin="normal"
//           variant="outlined"
//           color="secondary"
//           style={{ width: "520px", marginRight: "10px" }}
//           onChange={handleChange}
//         />
//         {/* <Button variant="content" style={{ float: "right" }} onClick={() => deleteItem( "children")}>Delete Children</Button> */}
//         {children.map((val, i) => (
//           <>
//             <FormControl style={{ marginTop: "16px" }}>
//               <InputLabel id={`children-${i}`}>Select Children</InputLabel>
//               <Select
//                 labelId={`children-${i}`}
//                 name="children-2"
//                 id={`demo-simple-select-children-${i}`}
//                 value={val.name}
//                 label="gender"
//                 onChange={handleChange}
//                 color="secondary"
//                 style={{ width: "520px", marginRight: "10px" }}
//               >
//                 <MenuItem value={"Daughter"}>Daughter</MenuItem>
//                 <MenuItem value={"Son"}>Son</MenuItem>
//               </Select>
//             </FormControl>

//             <TextField
//               label="Name of the Children"
//               margin="normal"
//               name="children-2-name"
//               variant="outlined"
//               color="secondary"
//               style={{ width: "520px" }}
//               onChange={handleChange}
//             />
//             <TextField
//               label="Age of the Children"
//               name="children-2-age"
//               margin="normal"
//               variant="outlined"
//               color="secondary"
//               style={{ width: "520px", marginRight: "10px" }}
//               onChange={handleChange}
//             />
//             <Button
//               variant="content"
//               style={{ float: "right" }}
//               onClick={() => deleteItem(i, "children")}
//             >
//               Delete Children
//             </Button>
//           </>
//         ))}
//       </div>
//     </>
//   );
// }





import React from "react";
import { TextField, FormControl, MenuItem, InputLabel, Select, Button } from "@mui/material";

export default function StepTwo({secondValues}) {
    const [religion, setReligion] = React.useState('');
    const [religions, setReligions] = React.useState('');
    const [change, setChanges] = React.useState('');
    const [occupation, setOccupation] = React.useState('');
    const [children, setChildren] = React.useState([]);
    const [childrens, setChildrens] = React.useState([]);
    const [siblings, setSiblings] = React.useState([]);
    const [sibling, setSibling] = React.useState([]);

    const [formData, setFormData] = React.useState({})

    secondValues(formData);


    const handleChangeReligion = (e) => {
        setReligion(e.target.value);
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value })
    };

    const handleReligions=(e)=>{
        setChanges(e.target.value);
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value })
    }

    const handleOtherChange=(e)=>{
        setChanges(e.target.value);
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value })
    }

   
    const handleChangeReligions = (e) => {
        setReligions(e.target.value);
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value })
    };

    const handleChangeSibling = (e) => {
        setSibling(e.target.value);
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value })
    };

    const handleChangeChildren = (e) => {
        setChildrens(e.target.value);
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value })
    };

 


    const handleChangeOccupation = (e) => {
        setOccupation(e.target.value);
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value })
    }

    const handleSibling = () => {
        setSiblings([...siblings, { name: "", lname: "" }]);
        
    };

    const handleChildren = () => {
        setChildren([...children, { name: "", lname: "" }]);
      
    };

    const handleChange = (e, i, type) => {
        const { name, value } = e.target;
         setFormData({ ...formData, [name]: value })
        const onChangeVal = [...(type === "children" ? children : siblings)];
        // onChangeVal[i][name] = value;
        type === "children" ? setChildren(onChangeVal) : setSiblings(onChangeVal);
    }

    const deleteItem = (i, type) => {
        const deleteValue = [...(type === "children" ? children : siblings)];
        deleteValue.splice(i, 1);
        type === "children" ? setChildren(deleteValue) : setSiblings(deleteValue);
    }

    return (
        <>
            <h1>Let's get to know your family</h1>
            <div style={{ margin: "10px" }}>
                {/* <TextField label="Father" margin="normal" variant="outlined" color="secondary" style={{ width: "300px", marginRight: "10px" }} />
                <TextField label="Mother" margin="normal" variant="outlined" color="secondary" style={{ width: "300px" }} /> */}

                 <FormControl style={{ marginTop: "16px" }}>
                    <InputLabel id="religion"> Salutation</InputLabel>
                    <Select
                        labelId="religion"
                        id="demo-simple-select"
                        value={religion}
                        name="father_saluation"
                        label="gender"
                        onChange={handleChangeReligion}
                        color="secondary" style={{ width: "100px", marginRight: "10px" }}
                    >
                        <MenuItem value={"Mr"}>Mr</MenuItem>
                        <MenuItem value={"Dr"}>Dr</MenuItem>
                        <MenuItem value={"late"}>Late</MenuItem>
                       
                    </Select>
                </FormControl>

                <TextField label="Fathers Name" margin="normal" 
                        name="father_name" onChange={handleOtherChange}
                        variant="outlined" color="secondary" style={{ width: "470px", marginRight: "10px" }} />
                <TextField label="Fathers Age" name="father_age" margin="normal" variant="outlined" onChange={handleOtherChange} color="secondary" style={{ width: "470px" }} />
               
                <FormControl style={{ marginTop: "16px" }}>
                    <InputLabel id="religions"> Salutation</InputLabel>
                    <Select
                        labelId="religions"
                        id="demo-simple-select"
                        value={religions}
                        name="mother_salutation"
                        label="religions"
                        onChange={handleChangeReligions}
                        color="secondary" style={{ width: "100px", marginRight: "10px" }}
                    >
                        <MenuItem value={"Mrs"}>Mrs</MenuItem>
                        <MenuItem value={"Dr"}>Dr</MenuItem>
                        <MenuItem value={"late"}>Late</MenuItem>
                       
                    </Select>
                </FormControl>

                <TextField label="Mothers Name"  name="mother_name" margin="normal"  onChange={handleOtherChange} variant="outlined" color="secondary" style={{ width: "470px", marginRight: "10px" }} />
                <TextField label="Mothers Age" onChange={handleOtherChange}  name="mother_age" margin="normal" variant="outlined" color="secondary" style={{ width: "470px" }} />
                 



                <FormControl style={{ marginTop: "16px" }}>
                    <InputLabel id="change"> Salutation</InputLabel>
                    <Select
                        labelId="change"
                        id="demo-simple-select"
                        name="spouse_salutation"
                        value={change}
                        label="gender"
                        onChange={handleReligions}
                        color="secondary" style={{ width: "100px", marginRight: "10px" }}
                    >
                        <MenuItem value={"Mrs"}>Mrs</MenuItem>
                        <MenuItem value={"Dr"}>Dr</MenuItem>
                        <MenuItem value={"late"}>Late</MenuItem>
                       
                    </Select>
                </FormControl>

                <TextField label="Spouse Name" margin="normal" name="spouse_name"variant="outlined" onChange={handleOtherChange}  color="secondary" style={{ width: "470px", marginRight: "10px" }} />
                <TextField label="Spouse Age" name="spouse_age" onChange={handleOtherChange} margin="normal" variant="outlined" color="secondary" style={{ width: "470px" }} />
               



                <h2 style={{ fontWeight: "600" }}>My Siblings Are<span><Button variant="content" style={{ float: "right" }} onClick={handleSibling}>Add Sibling</Button></span></h2>
               
                <FormControl style={{ marginTop: "16px" }}>
    <InputLabel id="sibling">Sibling</InputLabel>
    <Select
        labelId="sibling"
        id="demo-simple-select"
        value={sibling} // This seems incorrect
        label="sibling"
        name="sibling_1"
        onChange={handleChangeSibling} // This updates siblings to a non-array value
        color="secondary" style={{ width: "300px", marginRight: "10px" }}
    >
        {/* Menu items */}
        <MenuItem value={"Select Sibling"}>Select Sibling</MenuItem>
                        <MenuItem value={"Brother"}>Brother</MenuItem>
                        <MenuItem value={"Sister"}>Sister</MenuItem>
                       
   
    </Select>
</FormControl>

                        <TextField label="Name of the Sibling" name="sibling_1_name" margin="normal" variant="outlined" color="secondary" style={{ width: "350px",marginRight: "10px" }} onChange={(e) => handleChange(e,  "siblings")} />
                        <TextField label="Age of the Sibling" name="sibling_1_age" margin="normal" variant="outlined" color="secondary" style={{ width: "350px", marginRight: "10px" }} onChange={(e) => handleChange(e, "siblings")} />
                        {/* <Button variant="content" style={{ float: "right" }} onClick={() => deleteItem(i, "siblings")}>Delete Sibling</Button> */}
               
                {siblings.map((val, i) =>
                    <>
                        {/* <FormControl style={{ marginTop: "16px" }}>
                            <InputLabel id="sibling">Select Sibling</InputLabel>
                            <Select
                                labelId="sibling"
                                id={`demo-simple-select-sibling-${i}`}
                                name={`sibling_${i+2}`}
                                value={val.name}
                                label="gender"
                                onChange={(e) => handleChange(e, i, "siblings")}
                                color="secondary" style={{ width: "300px", marginRight: "10px" }}
                            >
                                <MenuItem value={"Brother"}>Brother</MenuItem>
                                <MenuItem value={"Sister"}>Sister</MenuItem>
                            </Select>
                        </FormControl> */}

<FormControl style={{ marginTop: "16px" }}>
                            <InputLabel id="sibling">Select Sibling</InputLabel>
                            <Select
     name={`sibling_${i+2}`}// Make sure this matches the property you want to update in your object
    labelId="sibling"
    id={`demo-simple-select-sibling-${i}`}
    value={val.type} // Assuming you store the selected type as `type` in your objects
    label="Sibling Type"
    onChange={(e) => handleChange(e, i, "siblings")}
    color="secondary" style={{ width: "300px", marginRight: "10px" }}
>
    <MenuItem value={"Brother"}>Brother</MenuItem>
    <MenuItem value={"Sister"}>Sister</MenuItem>
</Select>
                        </FormControl>



                        <TextField label="Name of the Sibling" name={`sibling_${i+2}_name`}  margin="normal" variant="outlined"  color="secondary" style={{ width: "350px",marginRight: "10px" }} onChange={(e) => handleChange(e, i, "siblings")} />
                        <TextField label="Age of the Sibling" name={`sibling_${i+2}_age`}margin="normal" variant="outlined" color="secondary" style={{ width: "350px", marginRight: "10px" }} onChange={(e) => handleChange(e, i, "siblings")} />
                        <Button variant="content" style={{ float: "right" }} onClick={() => deleteItem(i, "siblings")}>Delete Sibling</Button>
                    </>
                )}

                <h2 style={{ fontWeight: "600" }}>My children's Are <span><Button variant="content" style={{ float: "right" }} onClick={handleChildren}>Add Child</Button></span></h2>
               
                <FormControl style={{ marginTop: "16px" }}>
    <InputLabel id="childrens">childrens</InputLabel>
    <Select
        labelId="childrens"
        id="demo-simple-select"
        name="children_1"
        value={childrens} // This seems incorrect
        label="childrens"
        onChange={handleChangeChildren} // This updates siblings to a non-array value
        color="secondary" style={{ width: "320px", marginRight: "10px" }}
    >
        {/* Menu items */}
        <MenuItem value={"Select Sibling"}>Select Children</MenuItem>
                        <MenuItem value={"Son"}>Son</MenuItem>
                        <MenuItem value={"Daughter"}>Daughter</MenuItem>
                       
   
    </Select>
</FormControl>

                        <TextField label="Name of the Children" name="children_1_name"  margin="normal" variant="outlined" color="secondary" style={{ width: "350px" ,marginRight: "10px"}} onChange={(e) => handleChange(e, "children")} />
                        <TextField label="Age of the Children" name="children_1_age"  margin="normal" variant="outlined" color="secondary" style={{ width: "350px", marginRight: "10px" }} onChange={(e) => handleChange(e, "children")} />
                        {/* <Button variant="content" style={{ float: "right" }} onClick={() => deleteItem( "children")}>Delete Children</Button> */}
                {children.map((val, i) =>
                    <>
                    {/* <FormControl style={{ marginTop: "16px" }}>
                            <InputLabel id="sibling">Select Children</InputLabel>
                            <Select
                                labelId="children"
                                id={`demo-simple-select-sibling-${i}`}
                                value={val.name}
                                name={`children_${i+2}`}
                                label="Children"
                                onChange={(e) => handleChange(e, i, "siblings")}
                                color="secondary" style={{ width: "300px", marginRight: "10px" }}
                            >
                                <MenuItem value={"Son"}>Son</MenuItem>
                                <MenuItem value={"Daughter"}>Daughter</MenuItem>
                            </Select>
                        </FormControl> */}
                        <FormControl style={{ marginTop: "16px" }}>
                            <InputLabel id="sibling">Select Children</InputLabel>
                            <Select
      name={`children_${i+2}`} // Make sure this matches the property you want to update in your object
    labelId="sibling"
    id={`demo-simple-select-sibling-${i}`}
    value={val.type} // Assuming you store the selected type as `type` in your objects
    label="Sibling Type"
    onChange={(e) => handleChange(e, i, "siblings")}
    color="secondary" style={{ width: "300px", marginRight: "10px" }}
>
    <MenuItem value={"Son"}>Son</MenuItem>
    <MenuItem value={"Daughter"}>Daughter</MenuItem>
</Select>
                        </FormControl>

                        <TextField label="Name of the Sibling" margin="normal" variant="outlined" name={`children_${i+2}_name`}  color="secondary" style={{ width: "350px",marginRight: "10px" }} onChange={(e) => handleChange(e, i, "siblings")} />
                        <TextField label="Age of the Sibling" name={`children_${i+2}_age`} margin="normal" variant="outlined" color="secondary" style={{ width: "350px", marginRight: "10px" }} onChange={(e) => handleChange(e, i, "siblings")} />
                        <Button variant="content" style={{ float: "right" }} onClick={() => deleteItem(i, "children")}>Delete Children</Button>
                    </>
                )}
            </div>
        </>
    )
}