import React, { useState } from "react";
import { TextField, Select, MenuItem, InputLabel, FormControl, Checkbox, FormControlLabel } from "@mui/material";

export default function FirstStep({fifthValues}) {
  const [Occupation, setOccupation] = useState('');
  const [Occupation1, setOccupation1] = useState('');
  const [gender, setGender] = useState('');
  const [marriage, setMarriage] = useState('');
  const [will, setWill] = useState('');
  const [isCheckedFirst, setIsCheckedFirst] = useState(false);
  const [isCheckedSecond, setIsCheckedSecond] = useState(false);
  const [isThird, setIsCheckedthird] = useState(false);
  const [relationLabel, setRelationLabel] = useState('');
  const [dob, setDob] = React.useState('');
  const [witnessAge, setWitnessAge] = useState('');
  const [showAgeError, setShowAgeError] = useState(false);
  const [formData, setFormData] = useState({})

  
  fifthValues(formData)


  const handleChange = (event) => {
    setOccupation(event.target.value);
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value })
  };
  const handleGenderChange = (event) => {
    const selectedGender = event.target.value;
    setGender(selectedGender);

    // Set the relationship label based on the selected gender
    setRelationLabel(selectedGender === "male" ? "S/o" : "D/o");

    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value })
};


  const handleChangeMarriage = (e) => {
    setMarriage(e.target.value);
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value })
  };

 
  const handleDobChange = (event) => {
    const selectedDob = event.target.value;
    setWitnessAge(selectedDob);

    // Validate witness age
    const currentDate = new Date();
    const selectedDate = new Date(selectedDob);
    const ageDifference = currentDate.getFullYear() - selectedDate.getFullYear();

    // If witness age is less than 18, show the age error
    setShowAgeError(ageDifference < 18);
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value })
};

  const handleChangeWill = (e) => {
    setWill(e.target.value);
  };

  
  const handlethird = (event) => {
    setIsCheckedthird(!isThird);
    const { name, checked } = event.target;
    if(checked){
      setFormData({ ...formData, [name]: "checked" })
    }
    else {
      const updatedFormData = { ...formData };
      delete updatedFormData[name]; // Remove the key from formData
      setFormData(updatedFormData);
    }

  };



  const handleCheckboxChangeFirst = (event) => {
    setIsCheckedFirst(!isCheckedFirst);
   
    const { name, checked } = event.target;

    if(checked){
      setIsCheckedSecond(false);
      const updatedFormData = { ...formData, [name]: "checked" };
      delete updatedFormData['witness_checkbox_3']; 
      setFormData(updatedFormData)
    }
    else {
      const updatedFormData = { ...formData };
      delete updatedFormData[name]; // Remove the key from formData
      setFormData(updatedFormData);
    }

  };

  const handleCheckboxChangeSecond = (event) => {
    setIsCheckedSecond(!isCheckedSecond);
   

    const { name, checked } = event.target;


    if(checked){
      setIsCheckedFirst(false);

      const updatedFormData = { ...formData, [name]: "checked" };
      delete updatedFormData['witness_checkbox_2']; 
      setFormData(updatedFormData)
    }
    else {
      const updatedFormData = { ...formData };
      delete updatedFormData[name]; // Remove the key from formData
      setFormData(updatedFormData);
    }

  };


  return (
    <>
      <h1>Lets Finish</h1>
      <h3>My witnesses are </h3>
    <p color="grey">

If you have an Executor for the will, the same has also been prefilled as Witness for your convenience. Please change the Witness detail if you want someone else as Witness. Please note that Beneficiary cannot be a Witness.</p>
     
      <h2>First Witness</h2>
      <div style={{ margin: "10px" }}>
       
        <TextField label="FullName" name="witness_1_name" margin="normal" variant="outlined" color="secondary" style={{ width: "520px", marginRight: "10px" }} onChange={handleChange} />

        <FormControl style={{ marginTop: "16px" }}>
                <InputLabel id="gender">Gender</InputLabel>
                <Select
                    labelId="gender"
                    id="demo-simple-select"
                    value={gender}
                    name="witness_1_gender"
                    label="Gender"
                    onChange={handleGenderChange}
                    color="secondary"
                    style={{ width: "520px", marginRight: "10px" }}
                >
                    <MenuItem value={"male"}>Male</MenuItem>
                    <MenuItem value={"female"}>Female</MenuItem>
                </Select>
            </FormControl>
        
        <TextField label="Email"  name="witness_1_email" margin="normal" variant="outlined" color="secondary" style={{ width: "520px", marginRight: "10px" }}  onChange={handleChange}/>
        <TextField label="Mobile Number"  name="witness_1_mobile" margin="normal" variant="outlined" color="secondary" style={{ width: "520px", marginRight: "10px" }}  onChange={handleChange}/>
        <TextField
                label=""
                name="witness_1_dob"
                type="date"
                margin="normal"
                variant="outlined"
                color="secondary"
                onChange={handleDobChange}
                style={{ width: "520px", marginRight: "10px" }}
            />

            {showAgeError && (
                <p style={{ color: "red" }}>Witness should be 18 years or older.</p>
            )}
        <TextField label="PinCode"  name="witness_1_pincode" margin="normal" variant="outlined" color="secondary" style={{ width: "520px", marginRight: "10px" }}  onChange={handleChange} />
        <TextField label="FullAddress"  name="witness_1_address" margin="normal" variant="outlined" color="secondary" style={{ width: "520px" }}  onChange={handleChange} />
   

     
        <h2>Second Witness</h2>
       
        <TextField label="FullName"  name="witness_2_name" margin="normal" variant="outlined" color="secondary" style={{ width: "520px", marginRight: "10px" }}  onChange={handleChange}/>

        <FormControl style={{ marginTop: "16px" }}>
                <InputLabel id="gender">Gender</InputLabel>
                <Select
                    labelId="gender"
                    name="witness_2_gender"
                    id="demo-simple-select"
                    value={gender}
                    label="Gender"
                    onChange={handleGenderChange}
                    color="secondary"
                    style={{ width: "520px", marginRight: "10px" }}
                >
                    <MenuItem value={"male"}>Male</MenuItem>
                    <MenuItem value={"female"}>Female</MenuItem>
                </Select>
            </FormControl>
        
        <TextField label="Email"  name="witness_2_email" margin="normal" variant="outlined" color="secondary" style={{ width: "520px", marginRight: "10px" }}  onChange={handleChange} />
        <TextField label="Mobile Number"  name="witness_2_mobile" margin="normal" variant="outlined" color="secondary" style={{ width: "520px", marginRight: "10px" }}  onChange={handleChange} />
        <TextField
                label=""
                name="witness_2_dob"
                type="date"
                margin="normal"
                variant="outlined"
                color="secondary"
                onChange={handleDobChange}
                style={{ width: "520px", marginRight: "10px" }}
            />

            {showAgeError && (
                <p style={{ color: "red" }}>Witness should be 18 years or older.</p>
            )}
        <TextField label="PinCode"  name="witness_2_pincode" margin="normal" variant="outlined" color="secondary" style={{ width: "520px", marginRight: "10px" }}  onChange={handleChange} />
        <TextField label="FullAddress"  name="witness_2_address" margin="normal" variant="outlined" color="secondary" style={{ width: "520px" }}  onChange={handleChange} />
   

   


        <div style={{ marginTop: "16px" }}>
          <div>
            <p>I hereby declare that I am creating this Will with a sound mind and body</p>
          </div>
          <div>
          <FormControlLabel
              control={<Checkbox checked={isThird} onChange={handlethird} name="witness_checkbox_1"/>}
              label="I understand that this Will supersedes my existing Wills, if any"
            />
          </div>
          <div>
            <FormControlLabel
              control={<Checkbox checked={isCheckedFirst} onChange={handleCheckboxChangeFirst} name="witness_checkbox_2" />}
              label="I declare that I understand English and have read and understood the Terms of Use, Privacy Policy and I agree to the same."
            />
          </div>
          <div>
            <p>OR</p>
          </div>
          <div>
            <FormControlLabel
              control={<Checkbox checked={isCheckedSecond} onChange={handleCheckboxChangeSecond} name="witness_checkbox_3"/>}
              label="I hereby declare that I do not understand English and have taken help of a reliable source who has helped me with the creation of my WILL. I have understood the Terms of Use, Privacy Policy and I agree to the same."
            />
          </div>
        </div>
      </div>
    </>
  );
}


