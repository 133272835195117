import React, { useState } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import BusinessIcon from "@mui/icons-material/Business";

import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Check from "@mui/icons-material/Check";
import Person from "@mui/icons-material/Person";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShieldIcon from "@mui/icons-material/Shield";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";

import EditNoteIcon from "@mui/icons-material/EditNote";
import CheckIcon from "@mui/icons-material/Check";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { Typography, Button } from "@mui/material";
import FirstStep from "./stepOne";
import StepTwo from "./stepTwo";
import StepThree from "./stepThree";
import Stepfour from "./stepperFour";
import Stepfive from "./stepFive";
import StepSix from "./stepSix";
import Stepeight from "./stepEight";
import StepSeven from "./stepSeven";
import StepNine from "./stepNine";
import Will from "./Will";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
// import {navi} from 'react-router-dom';

import axios from "axios";
// import dotenv from "dotenv";

// dotenv.config();

// import Stepsix from './stepSix';

//////////////////////////////////////////////// STYLE ///////////////////////

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#784af4",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#784af4",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
  display: "flex",
  height: 22,
  alignItems: "center",
  ...(ownerState.active && {
    color: "#784af4",
  }),
  "& .QontoStepIcon-completedIcon": {
    color: "#784af4",
    zIndex: 1,
    fontSize: 18,
  },
  "& .QontoStepIcon-circle": {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
}));

function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
};

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(ownerState.completed && {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed } = props;

  const icons = {
    1: <Person />,
    2: <FavoriteIcon />,
    3: <ShieldIcon />,
    4: <BusinessIcon />,

    5: <EditNoteIcon />,
    6: <MonetizationOnIcon />,
    7: <CheckIcon />,
    8: <SupervisorAccountIcon />,
    9: <CreditCardIcon />,
    10: <CreditCardIcon />,
  };

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }}>
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

//////////////////////////////////////////////// STYLE ///////////////////////

function getSteps() {
  return [
    "About Me",
    "My Loved Ones",
    " Add Beneficiary",
    "Add Assets",
    "Assets Details",
    "Shares",
    "Completing My Will",
    "Excecutor",
    "Payment Info",
    "Will"
  ];
}

// // // Stepper function // // // // // // //

export default function CustomizedSteppers() {
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();

  var [newData, setNewData] = useState({});
  var [newValues, setnewValues] = useState({});

  function HandleValues(data) {
    setNewData(data);
    console.log("GetStepsContent", newData);
  }

  function GetStepsContent(step) {
    switch (step) {
      case 0:
        return <FirstStep firstValues={HandleValues} />;
      case 1:
        return <StepTwo secondValues={HandleValues} />;
      case 2:
        return <StepThree newValues={newValues} thirdValues={HandleValues} />;
      case 3:
        return <Stepfour fourthValues={HandleValues} />;
      case 4:
        return <StepNine ninthValues={HandleValues} newValues={newValues}/>;

      case 5:
        return <Stepeight newValues={newValues} eightValues={HandleValues}  />
      

      case 6:
        return <Stepfive fifthValues={HandleValues} />;

      case 7:
        return <StepSix sixthValues={HandleValues} />;
      case 8:
        return <StepSeven />;
      // case 8:
      //   return <Will newValues={newValues} />;

      // default:
      //   return "unknown step";
    }
  }

  const handleNext = () => {
    console.log("handleNext", newData);

    if (activeStep != 0) {
      var willify_id = localStorage.getItem("willify_id");
    } else {
      var willify_id = "";
    }

    console.log("willify_id in react", willify_id);
    console.log("newData", newData);

    async function apiCall() {
      var response = await axios.post(
        `http://app.willify.online/aboutMe/?willify_id=${
          willify_id ? willify_id : ""
        }`,
        newData
      );
      console.log("response", response.data);

      if (activeStep == 0) {
        localStorage.setItem("willify_id", response.data.willify_id);
      } else if (activeStep ==8){
        return(
          window.location.href = '/will'
        )
      }
      else{
        console.log("in active step", activeStep)  
        setnewValues(response.data);

      }

    }

    apiCall();
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  return (
    <>
      <Stepper
        alternativeLabel
        activeStep={activeStep}
        connector={<ColorlibConnector />}
      >
        {steps.map((step) => {
          const labelProps = {};
          const stepProps = {};
          return (
            <Step {...stepProps} key={step}>
              <StepLabel StepIconComponent={ColorlibStepIcon} {...labelProps}>
                {step}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length ? (
        <Typography variant="h3" align="center">
          Thank you
        </Typography>
      ) : (
        <>
       
          <form>{GetStepsContent(activeStep)}</form>
          <Button
            variant="contained"
            onClick={handleBack}
            disabled={activeStep === 0 &&  activeStep === steps.length - 1}
          >
            Back
          </Button>
          <Button
            variant="contained"
            onClick={handleNext}
            style={{ float: "right" }}
          >
            {activeStep === steps.length - 2 ? "Pay" : "next"}
            {/* {activeStep === steps.length - 1 ?""  : } */}
            
          </Button>
        </>
      )}
    </>
  );
}
